import React, {useEffect} from "react";
import "./DesignPlayGround.scss";
import { v4 as uuidv4 } from "uuid";
import "../../Classes/Button.scss";
import PlayGroundElement from "./PlayGroundElement/PlayGroundElement";
import { TDesignElementDataType } from "../../Types/DesignElementDataType";
import html2canvas from 'html2canvas';
import PlaygroundMenuBar from "./PlaygroundMenuBar/PlaygroundMenuBar";
import _ from "lodash";


type Props = {
  designElementData: TDesignElementDataType[] | null;
  onDataChange?: (designElementData: TDesignElementDataType[]) => void;
  onGenerateCanvas?: (canvas: HTMLCanvasElement) => void;
};

const DesignPlayGround: React.FC<Props> = ({ designElementData, onDataChange, onGenerateCanvas }) =>
{

  useEffect(() =>
  {
    // generatePlayGroundImage();
  }, [])

  const generatePlayGroundImage = async() =>
  {
    const playGround = document.getElementById("dpcID");
 
    if (playGround)
    {
      const canvas = await html2canvas(playGround, { allowTaint: true });
      onGenerateCanvas && onGenerateCanvas(canvas)
    }
  }

  const handleOnChange = (index:number, currentDesignElementData:TDesignElementDataType) =>
  {
    const copyOfDesignElementData = designElementData ? [...designElementData] : []
    copyOfDesignElementData[index] = currentDesignElementData;
    onDataChange && onDataChange(copyOfDesignElementData);
    generatePlayGroundImage();
  }


  return (
    <React.Fragment>
          <div className="design-playground-menubar">
        <PlaygroundMenuBar />
      </div>
    <div id="dpcID" className="design-playground-wrapper">
      {designElementData?.map((data, index) => <PlayGroundElement
          key={uuidv4()}
          dpeID={uuidv4()}
          elementData={data}
          onChange={(ed)=>handleOnChange(index, ed)}
        />
      )}
      </div>
    </React.Fragment>
  );
};

export default React.memo(DesignPlayGround);
