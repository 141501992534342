import React from "react";
import { useSelector } from "react-redux";
import "./QuotePreviewBody.css";
import QuotePreviewPerUnit from "./QuotePreviewPerUnit/QuotePreviewPerUnit";



const QuotePreviewBody = () =>
{

          const quoteData = useSelector(
    (state) => state.quote.quoteData
  );
  
        const projectCurrency = useSelector(
    (state) => state.project.currency
        );
  
          const paymentOption = useSelector(
    (state) => state.quote.paymentOption
          );
  
            const rentalPriceIdentifier = useSelector(
    (state) => state.quote.rentalPriceIdentifier
            );
  
              const project = useSelector(
    (state) => state.project
              );
  
                const designCanvasData = useSelector(
    (state) => state.designCanvasData
  );

  return (
    <div className="quote-preview-body-wrapper">
      {quoteData.map((data, index) => {
        return (
          <QuotePreviewPerUnit
            key={index}
            unitIndex={index}
            unitData={data}
            projectCurrency={projectCurrency}
            paymentOption={paymentOption}
            rentalPriceIdentifier={rentalPriceIdentifier}
            project={project}
            showImageInClientView={true}
            salesView={true}
            designCanvasData={designCanvasData}
            // handlePreviewImages={(e, i) => props.handlePreviewImages(e, i)}
          />
        );
      })}
    </div>
  );
};

export default QuotePreviewBody;
