import React from "react";
import "./QuotePreviewPerUnit.css";
import QuotePreviewPerRoom from "./QuotePreviewPerRoom/QuotePreviewPerRoom";
import formatAmountByCurrency from "../../../../../Shared/Functions/formatAmountByCurrency";

const QuotePreviewPerUnit = (props) => {
  const getTotalUnitItems = () => {
    let totalUnitItems = 0;
    props.unitData.quoteItems.forEach((quoteItem) => {
      const roomQty = quoteItem.room.qty ? quoteItem.room.qty : 1;
      quoteItem.items.forEach((item) => {
        if (quoteItem.room.description !== "Service") {
          if (props.paymentOption === "rental") {
            if (item.fields["Rentable"]) {
              totalUnitItems =
                totalUnitItems +
                item.fields[props.unitData.unit.fields.SKU] * roomQty;
            }
          } else {
            totalUnitItems =
              totalUnitItems +
              item.fields[props.unitData.unit.fields.SKU] * roomQty;
          }
        }
      });
    });

    return totalUnitItems;
  };

  return (
    <div className="quote-preview-per-unit-wrapper">
      <div className="quote-preview-per-unit-header">
        <h4>
          {"ITEMS IN " +
            props.unitData.unit.fields["Item Name"].toUpperCase() +
            " UNIT"}
        </h4>
        {props.paymentOption !== "rental" && (
          <div className="quote-preview-per-unit-header-totals-wrapper">
            <div className="quote-preview-per-unit-header-totals">
              <h5>Price/Unit: </h5>
              <h4>
                {formatAmountByCurrency(
                  props.unitData.unit.fields[
                    "Price (" + props.projectCurrency + ")"
                  ] +
                    props.unitData.unit.fields[
                      "Price of Services (" + props.projectCurrency + ")"
                    ],
                  props.projectCurrency
                )}
              </h4>
            </div>
            <div className="quote-preview-per-unit-header-totals">
              <h5>Items/Unit: </h5>
              <h4>{getTotalUnitItems()}</h4>
            </div>
          </div>
        )}

        {props.paymentOption === "rental" && (
          <div className="quote-preview-per-unit-header-totals-wrapper">
            <div className="quote-preview-per-unit-header-totals">
              <h5>Unit Rental/Month: </h5>
              <h4>
                {formatAmountByCurrency(
                  props.unitData.unit.fields[props.rentalPriceIdentifier],
                  props.projectCurrency
                )}
              </h4>
            </div>
            <div className="quote-preview-per-unit-header-totals">
              <h5>Rental Items: </h5>
              <h4>{getTotalUnitItems()}</h4>
            </div>
          </div>
        )}
      </div>
      <div className="quote-preview-per-unit-rooms">
        {props.unitData.roomList.map((room, index) => {
          return (
            <QuotePreviewPerRoom
              key={index}
              roomIndex={index}
              room={room}
              quoteItems={props.unitData.quoteItems}
              unit={props.unitData.unit}
              paymentOption={props.paymentOption}
              rentalPriceIdentifier={props.rentalPriceIdentifier}
              projectCurrency={props.projectCurrency}
              project={props.project}
              showImageInClientView={props.showImageInClientView}
              salesView={props.salesView}
              handlePreviewImages={(e, i) => props.handlePreviewImages(e, i)}
              designCanvasData={props.designCanvasData}
            />
          );
        })}
      </div>
    </div>
  );
};

export default QuotePreviewPerUnit;
