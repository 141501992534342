import React, { ReactElement, useState, useEffect } from "react";
import "./CustomDropDown.scss";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import OutsideClickHandler from "react-outside-click-handler";

type Props = {
  label: string;
  isActive?: boolean;
  component: ReactElement;
  displayComponent?: boolean;
  onDropdown?: (show: boolean) => void;
};

const CustomDropDown: React.FC<Props> = ({
  label,
  isActive,
  component,
  displayComponent,
  onDropdown,
}) => {
  const [showComponent, setShowComponent] = useState<boolean>(false);

  useEffect(() => {
    if (displayComponent !== undefined) {
      setShowComponent(displayComponent);
    }
  }, [displayComponent]);

  const handleClicked = (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    let _showComponent = showComponent;
    setShowComponent(!_showComponent);
    onDropdown && onDropdown(!_showComponent);
  };

  return (
    <OutsideClickHandler
      onOutsideClick={() => {
        setShowComponent(false);
        onDropdown && onDropdown(false);
      }}
    >
      <div className="custom-dropdown-wrapper">
        <div
          className={`custom-dropdown-label-wrapper ${
            isActive && "custom-dropdown-label-wrapper-active"
          }`}
          onClick={handleClicked}
        >
          <div className="custom-dropdown-label">{label}</div>
          {!showComponent && <FaChevronDown className="custom-dropdown-icon" />}
          {showComponent && <FaChevronUp className="custom-dropdown-icon" />}
        </div>

        {showComponent && (
          <div className="custom-dropdown-component-wrapper">{component}</div>
        )}
      </div>
    </OutsideClickHandler>
  );
};

export default CustomDropDown;
