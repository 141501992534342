import React, { useState } from "react";
import "./QuotePreviewPerItem.css";
import formatAmountByCurrency from "../../../../../../../Shared/Functions/formatAmountByCurrency";
import NoItemImageLogo from "../../../../../../../../Icons/no-item.png";

const QuotePreviewPerItem = (props) => {
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);

  const isEvenNumber = (number) => {
    return number % 2 === 0;
  };

  const selectImage = (event, imageIndex) => {
    if (selectedImageIndex === imageIndex) {
      props.handlePreviewImages(event);
      return;
    }

    setSelectedImageIndex(imageIndex);
  };

  return (
    <div
      className="quote-preview-per-item-wrapper"
      style={{
        background: isEvenNumber(props.itemIndex) && "#ffffff",
      }}
    >
      <div className="quote-preview-per-item-images-wrapper">
        <div className="quote-preview-per-item-images">
          {(props.item.images && props.item.images.length === 0) ||
          ((!props.item.images || !props.showImageInClientView) &&
            !props.salesView) ? (
            <div>
              <img width="20px" src={NoItemImageLogo} alt="no-item-img" />
            </div>
          ) : (
            props.item.images &&
            props.item.images.map((imageData, index) => {
              // opacity: selectedImageIndex === index ? "1" : "0.3",
              // width: selectedImageIndex === index ? "60px" : "35px",

              return (
                <div
                  key={index}
                  className="quote-preview-per-item-image"
                  style={{
                    opacity: selectedImageIndex === index ? "1" : "1",
                    backgroundImage: `url(//images.weserv.nl/?url=${imageData.urls[0]})`,

                    width: selectedImageIndex === index ? "60px" : "60px",
                  }}
                  onClick={(e) => selectImage(e, index)}
                ></div>
              );
            })
          )}
        </div>
        {props.salesView && !props.showImageInClientView && (
          <h5
            style={{
              margin: "0",
              color: "#ff4b30",
              fontSize: "calc(5px + 0.5vw)",
            }}
          >
            {props.item.images && "! Images will not been shown in client view"}
          </h5>
        )}
      </div>
      <div className="quote-preview-per-item-details">
        <h5 className="quote-preview-per-item-name">
          {props.item.fields["Item Name"]}
          {props.paymentOption === "rental" && (
            <p style={{ color: !props.item.fields["Rentable"] && "#ff4b30" }}>
              {props.item.fields["Rentable"] ? "Rentable" : "NOT Rentable"}
            </p>
          )}
        </h5>
        <h5 className="quote-preview-per-item-qty">
          {props.item.fields[props.unitSKU]}
        </h5>
        {props.item.images &&
          ((props.item.images[selectedImageIndex] &&
            props.paymentOption === "null") ||
            true) &&
          // props.project.title === "Point Zero Immobilier deal" &&

          props.item.images &&
          props.showImageInClientView && (
            <h5 className="quote-preview-per-item-amt">
              {formatAmountByCurrency(
                props.item.fields["Sale Price (" + props.projectCurrency + ")"],
                // props.item.images[selectedImageIndex]
                //   ? props.item.images[selectedImageIndex]["priceMAP"]
                //   : 0,

                props.projectCurrency
              )}
            </h5>
          )}
      </div>
    </div>
  );
};

export default QuotePreviewPerItem;
