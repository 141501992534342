import React from "react";
import "./ClientView.scss";
import QuotePreviewBody from "./QuotePreviewBody/QuotePreviewBody";



type Props = {
  handleSendToClient: ()=>void;
};


const ClientView:React.FC<Props> = ({handleSendToClient}) =>
{
  

    return (
      <div className="client-view-wrapper">
          <div className="client-view-top-nav">
              <div></div>
              <button className="button client-view-send-client-button" onClick={handleSendToClient}>Send to Quote Builder</button>
          </div>
          
          <div className="client-view-body">
          <p>Once you press send, the list below will be viewable by the client.<br />The draft selected will be duplicated as a version when you return to the design builder.</p>
          

          <QuotePreviewBody />
          </div>

            </div>
  );
};

export default ClientView;
