import React from "react";
import { IconType } from "react-icons";
import "./MenuBarIcon.scss";


type Props = {
Icon:IconType
};

const MenuBarIcon: React.FC<Props> = ({Icon}) =>
{

  return (
    <div className="menuBar-icon-wrapper">
        <Icon />
    </div>
  );
};

export default MenuBarIcon;
