import React, { useState,useEffect } from "react";
import "./RangeSelector.scss";

type Props = {
  minValue?: number;
  maxValue?: number;
  range: number;
  onChange?: (value: { minValue: number; maxValue: number }) => void;
};

let RangeSelector: React.FC<Props> = ({
  minValue = 0,
  maxValue = 0,
  range,
  onChange,
}) => {
  const [currentMinValue, setCurrentMinValue] = useState<number>(minValue);
  const [currentMaxValue, setCurrentMaxValue] = useState<number>(maxValue);
    const [currentRange, setCurrentRange] = useState<number>(range);
  const [minRangeDisplay, setMinRangeDisplay] = useState<string>("none");
  const [maxRangeDisplay, setMaxRangeDisplay] = useState<string>("none");
  const [mouseDown, setMouseDown] = useState<boolean>(true);


  useEffect(() => {
    if (range < maxValue) setCurrentRange(maxValue)
  }, [range, maxValue]);

  const handleRangeSliderStart = (event: React.MouseEvent) => {
    const id = (event.target as any).id;
    if (id === "maxValue-%$421") {
      setMinRangeDisplay("none");
      setMaxRangeDisplay("inherit");
    } else {
      setMaxRangeDisplay("none");
      setMinRangeDisplay("inherit");
    }
  };

  const handleRangeSliderStop = (event: React.MouseEvent) => {
    event.stopPropagation();
    event.preventDefault();
    setMinRangeDisplay("none");
    setMaxRangeDisplay("none");
  };

  let handleSlider = (event: React.ChangeEvent) => {
    event.stopPropagation();
    event.preventDefault();
    let copyOfCurrentMinValue = currentMinValue;
    let copyOfCurrentMaxValue = currentMaxValue;
    let copyOfCurrentRange = currentRange;

    const sliderName = (event.target as any).name;
    const sliderValue = (event.target as any).value
      ? parseInt((event.target as any).value)
      : (event.target as any).value;

    if (sliderName === "range1") {
      if (sliderValue <= copyOfCurrentMaxValue) {
        setCurrentMinValue(sliderValue);
        copyOfCurrentMinValue = sliderValue;
      }
    }

    if (sliderName === "range2") {
      if (sliderValue >= copyOfCurrentMinValue) {
        setCurrentMaxValue(sliderValue);
        console.log(copyOfCurrentRange, sliderValue)
        if (copyOfCurrentRange < sliderValue) setCurrentRange(sliderValue + 100)

       
        
        copyOfCurrentMaxValue = sliderValue;
      }
    }

    onChange &&
      onChange({
        minValue: copyOfCurrentMinValue,
        maxValue: copyOfCurrentMaxValue,
      });
  };


   console.log(currentRange)

  return (
    <div className="range-selector-wrapper">
      <div className="range-selector-sliders">
        <div className="range-selector-slide-background"></div>
        <div
          className="range-selector-slide1"
          style={{
            width: `${(currentMinValue * 100) / range}%`,
          }}
        >
          <div className="range-selector-slide1-range"></div>
          <div
            className="range-selector-slide-handle-wrapper slide1-handle"
            style={{
              right: `${-(15 - ((currentMinValue * 100) / range) * 0.15)}px`,
            }}
          >
            <div
              id="minValue-%$421"
              className="range-selector-slide-handle"
              style={
                minRangeDisplay === "inherit"
                  ? { backgroundColor: "#0070da" }
                  : {}
              }
              onMouseEnter={handleRangeSliderStart}
            ></div>
          </div>
        </div>
        <div
          className={`range-selector-slide2 ${
            (!currentMaxValue || currentMaxValue === 0) &&
            (!currentMinValue || currentMinValue === 0)
              ? "range-selector-slide2-bring-forward"
              : ""
          }`}
          style={{
            width: `${(currentMaxValue * 100) / range}%`,
          }}
        >
          <div className="range-selector-slide2-range"></div>
          <div
            className="range-selector-slide-handle-wrapper slide2-handle"
            style={{
              right: `${-(15 - ((currentMaxValue * 100) / range) * 0.15)}px`,
            }}
          >
            <div
              id="maxValue-%$421"
              className="range-selector-slide-handle"
              style={
                maxRangeDisplay === "inherit"
                  ? { backgroundColor: "#0070da" }
                  : {}
              }
              onMouseEnter={handleRangeSliderStart}
            ></div>
          </div>
        </div>

        <input
          style={{ display: minRangeDisplay }}
          className="range-selector-range1"
          type="range"
          name="range1"
          min="0"
          max={currentRange}
          value={currentMinValue}
          onChange={handleSlider}
          onMouseLeave={handleRangeSliderStop}
          onMouseMove={(e) => !mouseDown && handleRangeSliderStop(e)}
          onMouseDown={(e) => setMouseDown(true)}
          onMouseUp={(e) => setMouseDown(false)}
        />

        <input
          style={{ display: maxRangeDisplay }}
          className="range-selector-range2"
          type="range"
          name="range2"
          min="0"
          max={currentRange}
          value={currentMaxValue}
          onChange={handleSlider}
          onMouseLeave={handleRangeSliderStop}
          onMouseMove={(e) => !mouseDown && handleRangeSliderStop(e)}
          onMouseDown={(e) => setMouseDown(true)}
          onMouseUp={(e) => setMouseDown(false)}
        />
      </div>
      <div className="range-number-input-row">
        <div className="range-number-input-wrapper">
          <div className="range-number-input">
            <input
              value={currentMinValue === 0 ? "" : currentMinValue}
              placeholder="Min"
              type="number"
              name="range1"
              onChange={handleSlider}
            />
          </div>
        </div>
        <div className="range-number-input-wrapper">
          <div className="range-number-input">
            <input
              value={currentMaxValue === 0 ? "" : currentMaxValue}
              placeholder="Max"
              type="number"
              name="range2"
              onChange={handleSlider}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RangeSelector;
