import React, { useState } from "react";
import "./Filters.scss";
import { FaSlidersH } from "react-icons/fa";
import FilterByVendors from "./FilterByVendors/FilterByVendors";
import FilterByItemType from "./FilterByItemType/FilterByItemType";
import FilterBySourceAndAvailability from "./FilterBySourceAndAvailability/FilterBySourceAndAvailability";
import FilterByPrice from "./FilterByPrice/FilterByPrice";
import CustomDropDown from "../../../../Shared/Components/CustomDropDown/CustomDropDown";
import FilterByRoomsAndStyles from "./FilterByRoomsAndStyles/FilterByRoomsAndStyles";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import CheckBox from "../../../../Shared/Components/CheckBox/CheckBox";

const filters = [
  // { label: "Rooms & Styles", component: FilterByRoomsAndStyles },
  { label: "Vendors", component: FilterByVendors },
  { label: "Item Type", component: FilterByItemType },
  // { label: "Source / Availability", component: FilterBySourceAndAvailability },
  { label: "Price", component: FilterByPrice },
];

type TReduxStateSelector = {
  productsFilter: object;
};

type Props = {
  handleFilterProducts: (filterData: object) => void;
  handleFilterProductsFromAirtable?: (value: boolean) => void;
};

const Filters: React.FC<Props> = ({
  handleFilterProducts,
  handleFilterProductsFromAirtable,
}) => {
  const productsFilter: any = useSelector<TReduxStateSelector>(
    (state) => state.productsFilter
  );

  const [showFilters, setShowFilters] = useState<boolean>(true);
  const [activeFilter, setActiveFilter] = useState<string | null>(null);
  const [customFilteredFilters, setCustomFilteredFilters] = useState<string[]>(
    []
  );

  const dispatch = useDispatch();

  const handleHideFilters = (event: React.MouseEvent) => {
    const _showFilters = showFilters;
    setShowFilters(!_showFilters);
  };

  const handleCloseFilter = (value: boolean, filterName: string) => {
    if (filterName === activeFilter || activeFilter === null) {
      let currentActiveFilter = null;
      if (value) currentActiveFilter = filterName;
      setActiveFilter(currentActiveFilter);
    }
  };

  const handleChangeFilter = (filterData: object) => {};

  const updateFilterProductsFromAirtable = (value: boolean) => {
    dispatch({
      type: "fromAirtable",
      value,
    });
    handleFilterProductsFromAirtable && handleFilterProductsFromAirtable(value);
  };

  return (
    <div className="filters-wrapper">
      <FaSlidersH className="filters-filter-icon" onClick={handleHideFilters} />
      {showFilters && (
        <div className="filters-row">
          {filters.map((filter) => (
            <CustomDropDown
              key={uuidv4()}
              label={filter.label}
              isActive={customFilteredFilters.indexOf(filter.label) > -1}
              component={
                <filter.component
                  filterLabel={filter.label}
                  filterData={productsFilter}
                  customFilteredFilters={customFilteredFilters}
                  handleCustomFilteredFilters={(d) =>
                    setCustomFilteredFilters(d)
                  }
                  onFilter={handleFilterProducts}
                  onCancel={() => handleCloseFilter(false, filter.label)}
                />
              }
              displayComponent={activeFilter === filter.label}
              onDropdown={(v) => handleCloseFilter(v, filter.label)}
            />
          ))}
        </div>
      )}

      <div className="filters-filter-by-airtable">
        <CheckBox
          isChecked={false}
          label={"Airtable"}
          onClick={(v) => updateFilterProductsFromAirtable(v)}
        />
      </div>
    </div>
  );
};

export default Filters;
