import React from "react";
import "./PickerImagesClipboardModal.scss";

type Props = {
  imageURLs: string[] | null;
};

const PickerImagesClipboardModal: React.FC<Props> = ({ imageURLs }) => {
  return (
    <div className="picker-images-clipboard-modal-wrapper">
      <h6>Copy which image</h6>
      <div className="picker-images-clipboard-modal-images">
        {imageURLs &&
          imageURLs.map((imageURL, index) => (
            <img
              key={index}
              width="30%"
              src={`//images.weserv.nl/?url=${imageURL}`}
              alt="item"
            ></img>
          ))}
      </div>
    </div>
  );
};

export default PickerImagesClipboardModal;
