type TFetchRequest = (
  urlPath: string,
  method: "GET" | "POST" | "PATCH",
  body?: any
) => Promise<any>;

const fetchRequest: TFetchRequest = async (urlPath, method, body) => {
  let url = process.env.REACT_APP_SERVER_URL + urlPath;
  if (process.env.REACT_APP_ENV === "development") {
    url = urlPath;
  }

  let params = {};
  if (method === "GET") {
    params = {
      method: method,
      credentials: "include",
    };
  } else {
    params = {
      method: method,
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(body),
    };
  }

  const RESPONSE = await fetch(url, params);
  const RESPONSE_BODY = await RESPONSE.text();
  return JSON.parse(RESPONSE_BODY);
};

export default fetchRequest;
