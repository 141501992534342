import React from 'react';
import './SuccessModal.scss';
import NoResultsImage from "../../../../Images/no-results-found.png"
import { useHistory, useLocation } from 'react-router-dom';


type Props = {
  onClose: () => void;
}

const SuccessModal:React.FC<Props> = ({onClose}) =>
{

    const history = useHistory();
    const location = useLocation();

    const handleBackToProject = () =>
    {
        onClose()
        history.push(`/design${location.search}`);
    }
  
  return (
    <div className="success-modal-wrapper">
          <h6>{ `Designs have been sent for review!`}</h6>
      <div className="success-modal-image">
  <img width="80%" src={NoResultsImage} alt="no-results" />
          </div>
           <button className="button client-view-send-client-button" onClick={handleBackToProject}>Go back to the  project</button>
    </div>
  );
}

export default SuccessModal;