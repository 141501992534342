import React, { useState } from "react";
import "./ListDropDown.scss";
import { v4 as uuidv4 } from "uuid";
import "../../Classes/Button.scss";
import OutsideClickHandler from "react-outside-click-handler";

type Props = {
  label: string;
  labelPlaceholder?: string;
  prefixIcon?: any;
  suffixIcon?: any;
  dropdownFloatDirection?: "left" | "right";
  listItems: string[] | null;
  listItemClass?: string;
  listLabelClass?: string;
  labelIsActive?: boolean;
  updateLabelOnChange?: boolean;
  onSelectItem: (item: string) => void;
};

const ListDropDown: React.FC<Props> = ({
  label,
  labelPlaceholder,
  prefixIcon,
  suffixIcon,
  dropdownFloatDirection,
  listItems,
  listItemClass,
  listLabelClass,
  labelIsActive,
  updateLabelOnChange = false,
  onSelectItem,
}) => {
  type SShowList = boolean;
  type SDropDownLabel = string;

  const [showList, setShowList] = useState<SShowList>(false);
  const [dropDownLabel, setDropDownLabel] = useState<SDropDownLabel>(label);

  const handleOnClicked = (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    let _showList = showList;
    setShowList(!_showList);
  };

  const handleSelectItem = (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    setDropDownLabel((event.target as any).name);
    setShowList(false);
    onSelectItem((event.target as any).name);
  };

  return (
    <OutsideClickHandler
      onOutsideClick={() => {
        setShowList(false);
      }}
    >
      <div className="list-dropdown-wrapper">
        <div
          className={`list-dropdown-label-wrapper ${listLabelClass}`}
          style={
            dropDownLabel !== labelPlaceholder || labelIsActive
              ? { color: "#222222" }
              : {}
          }
          onClick={handleOnClicked}
        >
          {prefixIcon && prefixIcon}
          <div className="list-dropdown-label">
            {updateLabelOnChange ? label : dropDownLabel}
          </div>
          {suffixIcon && suffixIcon}
        </div>

        {showList && (
          <div
            className="list-dropdown-component-wrapper"
            style={dropdownFloatDirection === "left" ? { right: "0" } : {}}
          >
            {listItems?.map((item) => (
              <button
                key={uuidv4()}
                name={item}
                className={`button ${listItemClass}`}
                onClick={handleSelectItem}
              >
                {item}
              </button>
            ))}
          </div>
        )}
      </div>
    </OutsideClickHandler>
  );
};

export default ListDropDown;
