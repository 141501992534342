import React, {useEffect} from "react";
import fetchRequest from "../../Functions/fetchRequest";
import "./CanvasDisplay.scss";


type Props = {
  id: string;
  title?: string;
  canvas: HTMLCanvasElement;
  quoteID: string;
};


const CanvasDisplay: React.FC<Props> = ({id, title, canvas, quoteID = "MIMA" }) =>
{
  
  useEffect(() =>
  {
    const canvasHolder = document.getElementById(`canvas-holder-${id}`);
    canvas.style.width = "100%";
    canvas.style.height = "auto";
    canvas.setAttribute("id", `canvas-${id}`);
    canvasHolder?.append(canvas);
    generateCanvasImage();
  }, [])


  const generateCanvasImage = async() =>
  {
    const currentCanvas: HTMLCanvasElement = document.getElementById(`canvas-${id}`) as HTMLCanvasElement;

    try
    {
      const dataURL = currentCanvas?.toDataURL("image/png");
      let response = await fetchRequest(
      "/design-builder/design-data",
      "POST",
      {base64:dataURL, fileName: `${quoteID}-${title}`}
    );
      if (response.success)
      {
      console.log(response.message);
      //  setShowSuccessModal(true)
 
    } else {
      console.log(response.message);
    }
  }
  catch(err) {
    console.log("Error: " + err);
  }

// console.log(dataURL);
    

  }


  return (
    <div id={`canvas-holder-${id}`} className="canvas-display-wrapper">
      <h5 className="canvas-display-title">{title}</h5>
    </div>
  );
};

export default CanvasDisplay;
