import React from "react";
import "./DesignCanvas.scss";
import DesignPlayGround from "../../../Shared/Components/DesignPlayGround/DesignPlayGround";
import { TPickerData } from "../../../Shared/Types/PickerTypes";
import { useDispatch, useSelector } from "react-redux";
import DraftTabs from "../../../Shared/Components/DraftTabs/DraftTabs";
import { v4 as uuidv4 } from "uuid";
import ListDropDown from "../../../Shared/Components/ListDropDown/ListDropDown";
import { BiChevronDown } from "react-icons/bi";
import { TDesignElementDataType } from "../../../Shared/Types/DesignElementDataType";
import { TDesignDataType } from "../../../Shared/Types/DesignDataType";
import { TDesignCanvasDataType } from "../../../Shared/Types/DesignCanvasDataType";
import _ from "lodash";

type TReduxStateSelector = {
  project: object;
  quote: { quoteData: object[]; budget: number; projectCurrency: string };
  picker: TPickerData[];
  activeUnitIndex: number;
  selectedRoom: string | null;
  designData: TDesignDataType[];
  designCanvasData: TDesignCanvasDataType[];
};

const DesignCanvas = () => {
  const pickerData: any = useSelector<TReduxStateSelector>(
    (state) => state.picker
  );

  const currentSelectedRoom: any = useSelector<TReduxStateSelector>(
    (state) => state.selectedRoom
  );

  const designData: any = useSelector<TReduxStateSelector>(
    (state) => state.designData,
    () => true
  );

  const designCanvasData: any = useSelector<TReduxStateSelector>(
    (state) => state.designCanvasData,
    () => true
  );

  const dispatch = useDispatch();

  const handleDesignData = (
    designData: TDesignDataType[] | null,
    currentSelectedRoom: string,
    activeItemDataIndex: number,
    designElementData: TDesignElementDataType[]
  ) => {
    if (designData) {
      const copyOfDesignData = _.cloneDeep(designData);

      copyOfDesignData.forEach((dData: TDesignDataType, index: number) => {
        if (dData.room === currentSelectedRoom) {
          dData.draftData[activeItemDataIndex] = designElementData;
        }
      });
      dispatch({ type: "designData", value: copyOfDesignData });
    }
  };

  const getDesignData = (
    designData: TDesignDataType[] | null,
    currentSelectedRoom: string,
    activeItemDataIndex: number
  ): TDesignElementDataType[] | null => {
    let currentDesignData = null;
    if (designData) {
      designData.forEach((dData: TDesignDataType) => {
        if (dData.room === currentSelectedRoom) {
          currentDesignData = dData.draftData[activeItemDataIndex];
        }
      });
    }

    return currentDesignData;
  };

  const handleDesignCanvas = (
    currentSelectedRoom: string,
    activeItemDataIndex: number,
    canvas: HTMLCanvasElement
  ) => {
    let isNewRoom = true;
    if (designCanvasData) {
      const copyOfDesignCanvasData = _.cloneDeep(designCanvasData);

      copyOfDesignCanvasData.forEach(
        (canvasData: TDesignCanvasDataType, index: number) => {
          if (canvasData.room === currentSelectedRoom) {
            isNewRoom = false;
            canvasData.canvas[activeItemDataIndex] = canvas;
          }
        }
      );

      if (isNewRoom) {
        const newDesignCanvasData = {
          room: currentSelectedRoom,
          activeDraftIndex: activeItemDataIndex,
          canvas: [canvas],
        };
        copyOfDesignCanvasData.push(newDesignCanvasData);
      }
      dispatch({ type: "designCanvasData", value: copyOfDesignCanvasData });
    } else {
      dispatch({
        type: "designCanvasData",
        value: [
          {
            room: currentSelectedRoom,
            activeDraftIndex: activeItemDataIndex,
            canvas: [canvas],
          },
        ],
      });
    }
  };

  return (
    <div className="design-canvas-wrapper">
      <ListDropDown
        label={currentSelectedRoom}
        listLabelClass="design-canvas-room-dropdown-label"
        suffixIcon={<BiChevronDown />}
        dropdownFloatDirection="right"
        listItemClass="sort-item"
        listItems={
          pickerData && pickerData.map((data: TPickerData) => data.room)
        }
        updateLabelOnChange={true}
        onSelectItem={(r) => dispatch({ type: "selectedRoom", value: r })}
      />

      {pickerData &&
        pickerData.map((data: TPickerData, index: number) => {
          if (data.room === currentSelectedRoom) {
            return (
              <div key={uuidv4()} className="design-canvas-design-playground">
                <div className="design-canvas-design-playground-tabs">
                  <DraftTabs
                    items={data.items}
                    activeItemDataIndex={data.activeItemDataIndex}
                  />
                </div>
                <DesignPlayGround
                  designElementData={getDesignData(
                    designData,
                    currentSelectedRoom,
                    data.activeItemDataIndex
                  )}
                  onDataChange={(ed) =>
                    handleDesignData(
                      designData,
                      currentSelectedRoom,
                      data.activeItemDataIndex,
                      ed
                    )
                  }
                  onGenerateCanvas={(c) =>
                    handleDesignCanvas(
                      currentSelectedRoom,
                      data.activeItemDataIndex,
                      c
                    )
                  }
                />
              </div>
            );
          }
        })}
    </div>
  );
};

export default React.memo(DesignCanvas);
