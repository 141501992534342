import React, { useEffect, useState } from "react";
import { FaClipboardList } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import formatAmountByCurrency from "../../../../Shared/Functions/formatAmountByCurrency";
import "./ProductCard.scss";
import { format } from "date-fns";
import { GoCircleSlash, GoInfo } from "react-icons/go";
import { FiTruck } from "react-icons/fi";
import { BsQuestion } from "react-icons/bs";
import { IconType } from "react-icons/lib";
import RoomsMenu from "./RoomsMenu/RoomsMenu";
import {
  TItemData,
  TPickerData,
  TSelectedProductData,
} from "../../../../Shared/Types/PickerTypes";
import { TDesignElementDataType } from "../../../../Shared/Types/DesignElementDataType";
import { TDesignDataType } from "../../../../Shared/Types/DesignDataType";
import ProductDetailsModal from "../../../../Shared/Components/ProductDetailsModal/ProductDetailsModal";
import lodash from "lodash";

const stockCountStyleData = {
  inStock: { color: "#5dba7c", icon: null },
  outOfStock: { color: "#EB5757", icon: GoCircleSlash },
  reStockAvailable: { color: "#F2994A", icon: FiTruck },
  stockUnknown: { color: "#333333", icon: BsQuestion },
};

type TReduxStateSelector = {
  productsFilter: object;
  picker: TPickerData[];
  designData: TDesignDataType[] | null;
  quotePickerData: any;
  activeUnitIndex: number;
};

type Props = {
  product: any;
  quoteData: any;
};

const ProductCard: React.FC<Props> = ({ product, quoteData }) => {
  const productsFilter: any = useSelector<TReduxStateSelector>(
    (state) => state.productsFilter
  );

  const pickerData: any = useSelector<TReduxStateSelector>(
    (state) => state.picker
  );

  const designData: any = useSelector<TReduxStateSelector>(
    (state) => state.designData
  );

  const [showRooms, setShowRooms] = useState<boolean>(false);
  const [showProductDetails, setShowProductDetails] = useState<boolean>(false);

  const [productStockInfo, setProductStockInfo] = useState<{
    color: string;
    icon: IconType | null;
    info: string;
  }>({
    color: stockCountStyleData.stockUnknown.color,
    icon: stockCountStyleData.stockUnknown.icon,
    info: "Stock: No Info",
  });

  const dispatch = useDispatch();

  const quotePickerData: any = useSelector<TReduxStateSelector>(
    (state) => state.quotePickerData
  );

  const activeUnitIndex: any = useSelector<TReduxStateSelector>(
    (state) => state.activeUnitIndex
  );

  useEffect(() => {
    const getProductStockInfo = () => {
      if (product.stockQty !== 0) {
        setProductStockInfo({
          color: stockCountStyleData.inStock.color,
          icon: stockCountStyleData.inStock.icon,
          info: `Stock: ${product.stockQty} as of ${format(
            new Date(product.stockDate),
            "dd/MM/yy"
          )}`,
        });
        return;
      }

      if (product.restockDate) {
        setProductStockInfo({
          color: stockCountStyleData.reStockAvailable.color,
          icon: stockCountStyleData.reStockAvailable.icon,
          info: `Stock: ${product.stockQty} restock on ${format(
            new Date(product.restockDate),
            "dd/MM/yy"
          )}`,
        });
        return;
      }

      if (product.stockDate) {
        setProductStockInfo({
          color: stockCountStyleData.outOfStock.color,
          icon: stockCountStyleData.outOfStock.icon,
          info: `Stock: ${product.stockQty} as of ${format(
            new Date(product.stockDate),
            "dd/MM/yy"
          )}`,
        });
        return;
      }

      setProductStockInfo({
        color: stockCountStyleData.stockUnknown.color,
        icon: stockCountStyleData.stockUnknown.icon,
        info: "Stock: No Info",
      });
      return;
    };

    getProductStockInfo();
  }, []);

  const generateCategoriesPerRoom = () => {
    const categoriesPerRoom = quoteData.quoteItems.map((quoteItem: any) => {
      return {
        room: quoteItem.room.description,
        categories: quoteItem.items.map(
          (item: any) => item.fields["Item Name"]
        ),
      };
    });

    return categoriesPerRoom;
  };

  const setDesignData = (
    currentDesignData: TDesignDataType[] | null,
    currentRoom: string,
    draftData: TDesignElementDataType,
    activeDraftIndex: number
  ) => {
    let isNewRoom = true;
    let copyOfCurrentDesignData = currentDesignData
      ? [...currentDesignData]
      : null;

    if (copyOfCurrentDesignData) {
      copyOfCurrentDesignData.forEach((data) => {
        if (data.room === currentRoom) {
          isNewRoom = false;
          data.draftData[activeDraftIndex].push(draftData);
        }
      });

      if (isNewRoom) {
        const newDesignData = {
          room: currentRoom,
          activeDraftIndex,
          draftData: [[draftData]],
        };
        copyOfCurrentDesignData.push(newDesignData);
      }
    } else {
      copyOfCurrentDesignData = [
        { room: currentRoom, activeDraftIndex, draftData: [[draftData]] },
      ];
    }

    return copyOfCurrentDesignData;
  };

  const handleSelectedCategory = (
    selectedRoom: string,
    selectedCategory: string
  ) => {
    const copyOfPickerData = [...pickerData];
    let copyOfDesignData = designData ? [...designData] : null;

    copyOfPickerData.forEach((currentPickerData: TPickerData) => {
      if (currentPickerData.room === selectedRoom) {
        dispatch({ type: "selectedRoom", value: selectedRoom });
        currentPickerData.items[currentPickerData.activeItemDataIndex].forEach(
          (itemData: TItemData) => {
            if (itemData.item === selectedCategory) {
              const selectedProduct: TSelectedProductData = {
                images: product.imageURLs,
                price: product.MAP,
                qty: 1,
                imageName: product.itemName,
                product: product,
              };
              if (itemData.selectedProductData) {
                itemData.selectedProductData.push(selectedProduct);
              } else {
                itemData.selectedProductData = [selectedProduct];
              }

              const currentDesignData: TDesignElementDataType = {
                type: "image",
                url: product.imageURLs[0],
                text: undefined,
                metaData: { top: 0, left: 0, width: null, height: null },
              };

              copyOfDesignData = setDesignData(
                copyOfDesignData,
                selectedRoom,
                currentDesignData,
                currentPickerData.activeItemDataIndex
              );
            }
          }
        );
      }
    });
    const copyOfQuotePickerData = lodash.cloneDeep(quotePickerData);
    copyOfQuotePickerData[activeUnitIndex] = copyOfPickerData;
    dispatch({
      type: "quotePickerData",
      value: copyOfQuotePickerData,
    });

    dispatch({ type: "designData", value: copyOfDesignData });
    dispatch({ type: "picker", value: copyOfPickerData });
    dispatch({ type: "selectedRoom", value: selectedRoom });
  };

  return (
    <React.Fragment>
      <div className="product-card-wrapper">
        <div
          className="product-card"
          style={{
            backgroundImage: `url(//images.weserv.nl/?url=${product.imageURLs[0]})`,
          }}
        >
          <div
            className="product-card-item-qty"
            style={{
              backgroundColor: productStockInfo.color,
            }}
          >
            {productStockInfo.icon ? (
              <productStockInfo.icon className="product-card-item-stock-icon" />
            ) : (
              product.stockQty
            )}
          </div>
          <div className="product-card-overlay">
            <div className="product-card-overlay-item-summary">
              <h5>{product.itemName.toUpperCase()}</h5>
              <h5>{productStockInfo.info}</h5>
              <h5>
                {product.dimension}, unit: {product.dimensionUnit}
              </h5>
              <h5>
                weight: {product.weight}, unit: {product.weightUnit}
              </h5>
              <h5>
                MSRP: {formatAmountByCurrency(
                  product.MSRP,
                  product.tradeCurrency
                )}
                <br /> 
                MAP: {formatAmountByCurrency(
                  product.MAP,
                  product.tradeCurrency
                )}
                <br /> 
                Trade Price: {formatAmountByCurrency(
                  product.tradePrice,
                  product.tradeCurrency
                )}
              </h5>
            </div>
            <div className="product-card-overlay-action-buttons">
              <div className="product-card-overlay-action-button">
                <FaClipboardList />
                <div className="product-card-overlay-action-button-label">
                  <h4 onClick={() => setShowRooms(!showRooms)}>
                    Add to room...
                  </h4>
                  <div className="product-card-overlay-sub-action">
                    <RoomsMenu
                      roomsAndCategories={generateCategoriesPerRoom()}
                      handleSelectedCategory={handleSelectedCategory}
                    />
                  </div>
                </div>
              </div>
              <div
                className="product-card-overlay-action-button"
                onClick={() => setShowProductDetails(true)}
              >
                <GoInfo />
                <h4>Details</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ProductDetailsModal
        product={product}
        show={showProductDetails}
        onClose={() => setShowProductDetails(false)}
      />
    </React.Fragment>
  );
};

export default ProductCard;
