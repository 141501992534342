import React from "react";
import "./BackgroundImage.scss";

type Props = {
  imageURL: string;
  backgroundPosition?: string;
  height?: string;
  width?: string;
};

const BackgroundImage: React.FC<Props> = ({
  imageURL,
  backgroundPosition,
  height,
  width,
}) => {
  if (imageURL.includes("http"))
    imageURL = `//images.weserv.nl/?url=${imageURL}`;
  let style: { [key: string]: string } = {
    backgroundImage: `url(${imageURL})`,
  };
  if (backgroundPosition) style.backgroundPosition = backgroundPosition;
  if (height) style.height = height;
  if (width) style.width = width;

  return <div className="background-image-wrapper" style={style}></div>;
};

export default BackgroundImage;
