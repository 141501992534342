import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./DesignItemPicker.scss";
import Picker from "./Picker/Picker";
import { v4 as uuidv4 } from "uuid";
import BudgetDetails from "./BudgetDetails/BudgetDetails";
import getTotalNumberOfItems from "../../../Shared/Functions/getTotalNumberOfItems";
import getTotalNumberOfActiveUnitItems from "../../../Shared/Functions/getTotalNumberOfActiveUnitItems";
import { TPickerData } from "../../../Shared/Types/PickerTypes";
import { TItemQuantityValue } from "../../../Shared/Types/ItemQuantityValue";

type TProjectBudget = {
  totalNumberOfItems: number;
  currentItemCount: number;
  totalAmount: number;
  currentAmount: number;
};

type TReduxStateSelector = {
  project: object;
  quote: { quoteData: object[]; budget: number; projectCurrency: string };
  picker: TPickerData[];
  activeUnitIndex: number;
};

const DesignItemPicker = React.memo(() => {
  const quoteData: any = useSelector<TReduxStateSelector>(
    (state) => state.quote.quoteData
  );

  const activeUnitIndex: any = useSelector<TReduxStateSelector>(
    (state) => state.activeUnitIndex
  );

  const totalBudget: any = useSelector<TReduxStateSelector>(
    (state) => state.quote.budget
  );

  const projectCurrency: any = useSelector<TReduxStateSelector>(
    (state) => state.quote.projectCurrency
  );

  const [projectBudget, setProjectBudget] = useState<TProjectBudget>({
    totalNumberOfItems: getTotalNumberOfItems(quoteData),
    currentItemCount: 0,
    totalAmount: totalBudget,
    currentAmount: 0,
  });

  const [activeUnitBudget, setActiveUnitBudget] = useState<TProjectBudget>({
    totalNumberOfItems: getTotalNumberOfActiveUnitItems(
      quoteData[activeUnitIndex]
    ),
    currentItemCount: 0,
    totalAmount:
      quoteData[activeUnitIndex].unit.fields[`Price (${projectCurrency})`],
    currentAmount: 0,
  });

  const dispatch = useDispatch();

  //         const project: any = useSelector<TReduxStateSelector>(
  //     (state) => state.project
  //   );

  //   const quoteData: any = useSelector<TReduxStateSelector>(
  //     (state) => state.quote.quoteData
  //   );

  //   const activeUnitIndex: any = useSelector<TReduxStateSelector>(
  //     (state) => state.activeUnitIndex
  //   );

  const pickerData: any = useSelector<TReduxStateSelector>(
    (state) => state.picker
  );

  // console.log(quoteData)
  // console.log(pickerData)

  useEffect(() => {
    const currentStats = getAllPickerItemsStats(pickerData);
    const defaultStats = {
      totalNumberOfItems: getTotalNumberOfItems(quoteData),
      currentItemCount: 0,
      totalAmount: totalBudget,
      currentAmount: 0,
    };
    const copyOfProjectBudget = { ...defaultStats };
    const copyOfActiveUnitBudget = { ...defaultStats };

    copyOfProjectBudget.currentItemCount = currentStats.count;
    copyOfActiveUnitBudget.currentItemCount = currentStats.count;

    copyOfProjectBudget.currentAmount = currentStats.price;
    copyOfActiveUnitBudget.currentAmount = currentStats.price;

    // console.log(copyOfProjectBudget, copyOfActiveUnitBudget)

    setProjectBudget(copyOfProjectBudget);
    setActiveUnitBudget(copyOfActiveUnitBudget);
  }, [pickerData]);

  const getAllPickerItemsStats = (
    currentPickerData: TPickerData[]
  ): { count: number; price: number } => {
    let allPickerItemsCount = 0;
    let allPickerItemsAmount = 0;
    currentPickerData?.forEach((picker) => {
      picker.items.forEach((pickerItem) => {
        pickerItem.forEach((item) => {
          if (item && item.selectedProductData) {
            item.selectedProductData.forEach((productData) => {
              allPickerItemsCount = allPickerItemsCount + productData.qty;
              allPickerItemsAmount = allPickerItemsAmount + productData.price;
            });
          }
        });
      });
    });

    return { count: allPickerItemsCount, price: allPickerItemsAmount };
  };

  const onSelectedProductQtyChange = (
    value: TItemQuantityValue,
    pickerDataIndex: number,
    itemDataIndex: number,
    selectedProductIndex: number
  ) => {
    let pickerDataRoomData = pickerData[pickerDataIndex];

    const currentSelectedProductQty =
      pickerDataRoomData.items[pickerDataRoomData.activeItemDataIndex][
        itemDataIndex
      ].selectedProductData[selectedProductIndex].qty;

    const copyOfPickerData = [...pickerData];
    let currentSelectedProductData =
      copyOfPickerData[pickerDataIndex].items[
        pickerDataRoomData.activeItemDataIndex
      ][itemDataIndex].selectedProductData;

    if (value === "decrement")
      currentSelectedProductData[selectedProductIndex].qty =
        currentSelectedProductQty - 1;

    if (value === "increment")
      currentSelectedProductData[selectedProductIndex].qty =
        currentSelectedProductQty + 1;

    if (currentSelectedProductData[selectedProductIndex].qty === 0)
      currentSelectedProductData.splice(selectedProductIndex, 1);

    if (value === "delete")
      currentSelectedProductData.splice(selectedProductIndex, 1);
    dispatch({ type: "picker", value: copyOfPickerData });
  };

  return (
    <div className="design-item-picker-wrapper">
      <div className="design-item-picker-stats-bar">
        <BudgetDetails
          title="Project Cost with selected Drafts:"
          totalNumberOfItems={projectBudget.totalNumberOfItems}
          currentItemCount={projectBudget.currentItemCount}
        />
        <div style={{ minWidth: "1px", minHeight: "calc(3px + 0.3vw)" }}></div>
        <BudgetDetails
          title="Unit Cost with selected Drafts:"
          totalNumberOfItems={activeUnitBudget.totalNumberOfItems}
          currentItemCount={activeUnitBudget.currentItemCount}
        />
      </div>
      <div className="design-item-picker-data-section">
        {pickerData?.map((data: TPickerData, index: number) => (
          <Picker
            key={uuidv4()}
            title={data.room}
            items={data.items}
            currency={data.currency}
            activeItemDataIndex={data.activeItemDataIndex}
            onSelectedProductQtyChange={(v, ii, pi) =>
              onSelectedProductQtyChange(v, index, ii, pi)
            }
          />
        ))}
      </div>
    </div>
  );
});

export default DesignItemPicker;
