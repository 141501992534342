import { Reducer } from "redux";

type TReduxReducer = Reducer;

const ReduxReducer: TReduxReducer = (state, action) =>
{
  
  
    return {
      ...state,
      [action.type]: action.value,
    };
    
  
  // if (action.type === "project") {
  //   return {
  //     ...state,
  //     project: action.value,
  //   };
  // }

  // if (action.type === "quote") {
  //   return {
  //     ...state,
  //     quote: action.value,
  //   };
  // }

  //   if (action.type === "picker") {
  //   return {
  //     ...state,
  //     picker: action.value,
  //   };
  //   }
  
  //     if (action.type === "designData") {
  //   return {
  //     ...state,
  //     designData: action.value,
  //   };
  //   }
  
  //     if (action.type === "selectedRoom") {
  //   return {
  //     ...state,
  //     selectedRoom: action.value,
  //   };
  // }


  // if (action.type === "activeUnitIndex") {
  //   return {
  //     ...state,
  //     activeUnitIndex: action.value,
  //   };
  // }

  // if (action.type === "productsData") {
  //   return {
  //     ...state,
  //     productsData: action.value,
  //   };
  // }

  // if (action.type === "productsFilter") {
  //   return {
  //     ...state,
  //     productsFilter: action.value,
  //   };
  // }

  // return state;
};

export type TAppState = ReturnType<typeof ReduxReducer>;

export default ReduxReducer;
