import React, { useState } from "react";
import { useSelector } from "react-redux";
import getActiveItems from "../../../../../Shared/Functions/getActiveItems";
import FilterActionButtons from "../FilterActionButtons/FilterActionButtons";
import "./FilterByItemType.scss";
import { v4 as uuidv4 } from "uuid";
import CheckBox from "../../../../../Shared/Components/CheckBox/CheckBox";
import ListDropDown from "../../../../../Shared/Components/ListDropDown/ListDropDown";
import { BiChevronDown } from "react-icons/bi";
import InputBox from "../../../../../Shared/Components/InputBox/InputBox";
import RangeSelector from "../../../../../Shared/Components/RangeSelector/RangeSelector";

const unitOfMeasureMap: { [key: string]: string | null } = {
  "All Unit": null,
  Inches: "in",
  Meters: "m",
  Pounds: "lbs",
  Kilograms: "kg",
};

type TReduxStateSelector = {
  quote: { quoteData: object[]; budget: number; projectCurrency: string };
  activeUnitIndex: number;
};

type TFilterParams = {
  items: string[];
  weightMin: number | null;
  weightMax: number | null;
  weightUnit: string | null;
  dimensionUnit: string | null;
  width: number | null;
  height: number | null;
  length: number | null;
};

type Props = {
  filterLabel: string;
  filterData: {
    filterParams: TFilterParams;
  };
  customFilteredFilters: string[];
  handleCustomFilteredFilters: (customFilteredFilters: string[]) => void;
  onFilter: (filterData: object) => void;
  onCancel: (actionName: string) => void;
};

const FilterByItemType: React.FC<Props> = ({
  filterLabel,
  filterData,
  customFilteredFilters,
  handleCustomFilteredFilters,
  onFilter,
  onCancel,
}) => {
  const quoteData: any = useSelector<TReduxStateSelector>(
    (state) => state.quote.quoteData
  );

  const activeUnitIndex: any = useSelector<TReduxStateSelector>(
    (state) => state.activeUnitIndex
  );

  const [allItems, setAllItems] = useState<string[]>(
    getActiveItems(quoteData[activeUnitIndex], "All")
  );

  const [selectedItems, setSelectedItems] = useState<string[]>(
    filterData.filterParams.items
  );

  const [filterParams, setFilterParams] = useState<TFilterParams>(
    filterData.filterParams
  );

  const handleDimensionInput = (event: React.ChangeEvent) => {
    let newFilterParams = { ...filterParams };
    const eventTarget = event.target as any;
    (newFilterParams as any)[eventTarget.name] = eventTarget.value;
    setFilterParams(newFilterParams);
  };

  const handleSelectUnitOfMeasure = (
    unitName: string,
    unitDescription: string
  ) => {
    let newFilterParams = { ...filterParams };
    (newFilterParams as any)[unitDescription] = unitOfMeasureMap[unitName];

    setFilterParams(newFilterParams);
  };

  const handleWeightSliderValues = (value: {
    minValue: number;
    maxValue: number;
  }) => {
    let newFilterParams = { ...filterParams };
    newFilterParams.weightMin = value.minValue;
    newFilterParams.weightMax = value.maxValue;
    setFilterParams(newFilterParams);
  };

  const handleItemFilterByName = (value: boolean, itemName: string) => {
    let newSelectedItems = selectedItems.filter(
      (item: string) => item !== itemName
    );

    if (value) {
      newSelectedItems.push(itemName);
    }

    setSelectedItems(newSelectedItems);
  };

  const handleActionButton = (actionName: string) => {
    let newCustomFilteredFilters = [...customFilteredFilters];
    let filterIndex = newCustomFilteredFilters.indexOf(filterLabel);
    if (
      selectedItems.length !== allItems.length ||
      filterParams.weightMax ||
      filterParams.weightMin ||
      filterParams.dimensionUnit ||
      filterParams.weightUnit ||
      filterParams.width ||
      filterParams.height ||
      filterParams.length
    ) {
      if (!(filterIndex > -1)) {
        newCustomFilteredFilters.push(filterLabel);
      }
    } else {
      if (filterIndex > -1) {
        newCustomFilteredFilters.splice(filterIndex, 1);
      }
    }

    handleCustomFilteredFilters(newCustomFilteredFilters);

    if (actionName === "apply") {
      let newFilterData = { ...filterData };
      newFilterData.filterParams = filterParams;
      newFilterData.filterParams.items = selectedItems;
      console.log(newFilterData);
      onFilter(newFilterData);
    }

    onCancel(actionName);
  };

  return (
    <div className="filter-by-item-type-wrapper">
      <div className="filter-by-item-type-top-section">
        <div className="filter-by-item-type-top-left-section">
          <h5>Item Type</h5>
          <div className="filter-by-item-type-top-left-section-items">
            <div
              key={uuidv4()}
              className="filter-by-item-type-top-left-section-item"
            >
              <CheckBox
                isChecked={allItems.length === selectedItems.length}
                label={`All (${allItems.length})`}
                onClick={(v) =>
                  v ? setSelectedItems(allItems) : setSelectedItems([])
                }
              />
            </div>
            {allItems.map((item: string) => (
              <div
                key={uuidv4()}
                className="filter-by-item-type-top-left-section-item"
              >
                <CheckBox
                  isChecked={selectedItems.indexOf(item) > -1}
                  label={item}
                  onClick={(v) => handleItemFilterByName(v, item)}
                />
              </div>
            ))}
          </div>
        </div>
        {/* <div className="filter-by-item-type-top-right-section">
          <div className="filter-by-item-type-top-right-section-selection-wrapper">
            <div className="filter-by-item-type-top-right-section-selection-header">
              <h5>Dimension</h5>
              <ListDropDown
                label={
                  filterParams.dimensionUnit
                    ? filterParams.dimensionUnit
                    : "All Unit"
                }
                labelIsActive={true}
                suffixIcon={<BiChevronDown />}
                dropdownFloatDirection="right"
                listLabelClass="filter-by-item-type-top-right-section-selection-label"
                listItemClass="filter-by-item-type-top-right-section-selection-item"
                listItems={["All Unit", "Inches", "Meters"]}
                onSelectItem={(u) =>
                  handleSelectUnitOfMeasure(u, "dimensionUnit")
                }
              />
            </div>
            <div className="filter-by-item-type-top-right-section-selection-inputs">
              <div className="filter-by-item-type-top-right-section-selection-input">
                <InputBox
                  name="width"
                  type="number"
                  placeholder="W"
                  onChange={handleDimensionInput}
                />
              </div>
              <div className="filter-by-item-type-top-right-section-selection-input">
                <InputBox
                  name="length"
                  type="number"
                  placeholder="L"
                  onChange={handleDimensionInput}
                />
              </div>
              <div className="filter-by-item-type-top-right-section-selection-input">
                <InputBox
                  name="height"
                  type="number"
                  placeholder="H"
                  onChange={handleDimensionInput}
                />
              </div>
            </div>
          </div>
          <div className="filter-by-item-type-top-right-section-selection-wrapper">
            <div className="filter-by-item-type-top-right-section-selection-header">
              <h5>Weight</h5>
              <ListDropDown
                label={
                  filterParams.weightUnit ? filterParams.weightUnit : "All Unit"
                }
                labelIsActive={true}
                suffixIcon={<BiChevronDown />}
                dropdownFloatDirection="right"
                listLabelClass="filter-by-item-type-top-right-section-selection-label"
                listItemClass="filter-by-item-type-top-right-section-selection-item"
                listItems={["All Unit", "Pounds", "Kilograms"]}
                onSelectItem={(u) => handleSelectUnitOfMeasure(u, "weightUnit")}
              />
            </div>
            <div className="filter-by-item-type-top-right-section-selection-input">
              <RangeSelector
                range={1000}
                minValue={filterParams.weightMin ? filterParams.weightMin : 0}
                maxValue={filterParams.weightMax ? filterParams.weightMax : 0}
                onChange={handleWeightSliderValues}
              />
            </div>
          </div>
        </div> */}
      </div>

      <FilterActionButtons onClick={handleActionButton} />
    </div>
  );
};

export default FilterByItemType;
