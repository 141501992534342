import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { v4 } from "uuid";
import "./ProductModal.css";
import formatAmountByCurrency from "../../../../Shared/Functions/formatAmountByCurrency";

const ProductModal = (props) => {
  return (
    <div className="productDetails-modal">
      <div className="productDetails-modal-carousel-container">
        <Carousel width={"450px"} showArrows={true} showThumbs={false}>
          {props.item.imageURLs.map((url) => {
            return (
              <img
                key={v4()}
                alt="product-img"
                style={{ width: "450px" }}
                src={`//images.weserv.nl/?url=${url}`}
              />
            );
          })}
        </Carousel>
      </div>
      <div className="productDetails-modal-title-container">
        <div className="productDetails-modal-title">
          <h6 className="productDetails-modal-vendor">
            BY {props.item.vendorName.toUpperCase()}
          </h6>
          <h4 className="productDetails-modal-name">{props.item.itemName}</h4>
          <h4 className="productDetails-modal-price">
            MSRP: {formatAmountByCurrency(props.item.MSRP, props.item.tradeCurrency)}, 
            MAP: {formatAmountByCurrency(props.item.MAP, props.item.tradeCurrency)}, 
            Trade Price: {formatAmountByCurrency(props.item.tradePrice, props.item.tradeCurrency)}
          </h4>
        </div>
      </div>
      <div className="productDetails-modal-description-container">
        <p>{props.item.vendorItemDescription}</p>
      </div>
      <div className="productDetails-modal-details">
        <div className="productDetails-row">
          <h5>DIMENSIONS (unit: {props.item.dimensionUnit})</h5>{" "}
          <span>{props.item.dimension}</span>
        </div>
        <div className="productDetails-row">
          <h5>WEIGHT (unit: {props.item.weightUnit})</h5>{" "}
          <span>{props.item.weight}</span>
        </div>
        <div className="productDetails-row">
          <h5>METERIALS</h5> <span>{props.item.material}</span>
        </div>
        <div className="productDetails-row">
          <h5>COLOR</h5> <span>{props.item.color}</span>
        </div>
        <div className="productDetails-row">
          <h5>Stock</h5>{" "}
          <span>
            {props.item.stockQty} as of{" "}
            {props.item.stockDate && props.item.stockDate.split("T")[0]}
          </span>
        </div>
        {props.item.stockQty === 0 && props.item.restockDate && (
          <div className="productDetails-row">
            <h5>RESTOCK DATE</h5>{" "}
            <span>{props.item.restockDate.split("T")[0]}</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProductModal;
