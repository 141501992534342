import React from "react";
import "./LandingPage.scss";

const LandingPage = () =>
{

  return (
    <div>
      Landing Page
    </div>
  );
};

export default LandingPage;
