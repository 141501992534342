type TGetActiveItems = (
  activeQuoteData: any,
  roomNames: string[] | "All"
) => [];

const getActiveItems: TGetActiveItems = (activeQuoteData, roomNames) => {
  let activeItems = [];

  if (roomNames === "All") {
    activeItems = activeQuoteData.quoteItems.map((quoteItem: any) =>
      quoteItem.items.map((item: any) => item.fields["Item Name"])
    );
  }

  return activeItems.join().split(",");
};

export default getActiveItems;
