import React, { useState, useEffect } from "react";
import { FiCheck } from "react-icons/fi";
import { FiX } from "react-icons/fi";
import "./CheckBox.scss";

type Props = {
  isChecked: boolean;
  cancelIcon?: boolean;
  label?: string;
  onClick?: (value: boolean) => void;
};

const CheckBox: React.FC<Props> = ({
  isChecked,
  cancelIcon,
  label,
  onClick,
}) => {
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    if (isChecked !== undefined) {
      setChecked(isChecked);
    }
  }, [isChecked]);

  const handleCheckBox = (event: React.MouseEvent) => {
    const currentCheckValue = checked;
    setChecked(!currentCheckValue);
    onClick && onClick(!currentCheckValue);
  };

  return (
    <div className="checkbox-wrapper">
      {!cancelIcon && (
        <div
          style={
            checked
              ? {}
              : {
                  backgroundColor: "transparent",
                  color: "transparent",
                }
          }
          className="checkbox"
          onClick={(e) => handleCheckBox(e)}
        >
          <FiCheck
            style={
              checked
                ? {}
                : { backgroundColor: "transparent", color: "transparent" }
            }
            className="checkbox-icon"
          />
        </div>
      )}
      {cancelIcon && (
        <div
          className="checkbox"
          style={checked ? {} : { backgroundColor: "transparent" }}
          onClick={(e) => handleCheckBox(e)}
        >
          <FiX
            style={checked ? {} : { backgroundColor: "transparent" }}
            className="checkbox-icon"
          />
        </div>
      )}
      {label && (
        <div
          style={{
            minWidth: "5px",
            minHeight: "5px",
          }}
        ></div>
      )}
      <p
        className="checkbox-label"
        style={{ fontWeight: checked ? "bold" : "normal" }}
      >
        {label}
      </p>
    </div>
  );
};

export default CheckBox;
