import React from "react";
import "./Sort.scss";
import { TiArrowUnsorted } from "react-icons/ti";
import ListDropDown from "../../../../Shared/Components/ListDropDown/ListDropDown";
import { useSelector } from "react-redux";

type Props = {
  handleSortProducts: (filterData: object) => void;
};

type TReduxStateSelector = {
  productsFilter: object;
};

const Sort: React.FC<Props> = ({ handleSortProducts }) => {

  const productsFilter: any = useSelector<TReduxStateSelector>(
    (state) => state.productsFilter
  );

  const handleSelect = (item: string) => {
    console.log('pressed', item);
    if (item === "Colour similarity" || item === "Image similarity") return;

    const newProductsFilter = {...productsFilter, sort: true};
    if (item === "Price (low to high)") {
      newProductsFilter.filterParams.sortPrice = "ascending";
      newProductsFilter.filterParams.sortStockQuantity = null;
    }
    if (item === "Price (high to low)") {
      newProductsFilter.filterParams.sortPrice = "descending";
      newProductsFilter.filterParams.sortStockQuantity = null;
    }
    if (item === "Stock quantity") {
      newProductsFilter.filterParams.sortPrice = null;
      newProductsFilter.filterParams.sortStockQuantity = "descending";
    }

    handleSortProducts(newProductsFilter);
  };

  return (
    <div className="sort-wrapper">
      <ListDropDown
        label="Sort by"
        prefixIcon={<TiArrowUnsorted />}
        dropdownFloatDirection="left"
        listItemClass="sort-item"
        listItems={[
          "Price (low to high)",
          "Price (high to low)",
          "Stock quantity",
          "Colour similarity",
          "Image similarity",
        ]}
        onSelectItem={handleSelect}
      />
    </div>
  );
};

export default Sort;
