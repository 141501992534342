import React, { useEffect, useState } from "react";
import PageModal from "../PageModal/PageModal";
import ProductModal from "./ProductModal/ProductModal";


type Props = {
  product: any;
    show: boolean;
    onClose: () => void;
};

const ProductDetailsModal: React.FC<Props> = ({ product, show, onClose}) => {
    const [showProductDetail, setShowProductDetail] = useState<boolean>(false);
    
    useEffect(() => {
setShowProductDetail(show)
    }, [show])

  return (
              <PageModal
          visible={showProductDetail}
          component={<ProductModal item={product} />}
          onClose={onClose}
        />
  );
};

export default ProductDetailsModal;
