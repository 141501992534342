import React, { useState } from "react";
import formatAmountByCurrency from "../../../../Shared/Functions/formatAmountByCurrency";
import "./Picker.scss";
import { CgChevronUpO } from "react-icons/cg";
import { CgChevronDownO } from "react-icons/cg";
import { v4 as uuidv4 } from "uuid";
import { FiSearch } from "react-icons/fi";
import { TItemData } from "../../../../Shared/Types/PickerTypes";
import PickerItemDetails from "./PickerItemDetails/PickerItemDetails";
import DraftTabs from "../../../../Shared/Components/DraftTabs/DraftTabs";
import { TItemQuantityValue } from "../../../../Shared/Types/ItemQuantityValue";

type Props = {
  title: string;
  items: TItemData[][];
  activeItemDataIndex: number;
  currency: string;
  onSelectedProductQtyChange: (
    value: TItemQuantityValue,
    itemDataIndex: number,
    selectedProductIndex: number
  ) => void;
};

const Picker: React.FC<Props> = ({
  title,
  items,
  activeItemDataIndex = 0,
  currency,
  onSelectedProductQtyChange,
}) => {
  const [showRoomDetails, setShowRoomDetails] = useState<boolean>(true);

  const getAllItemCountStats = (): { stats: string; status: number } => {
    let expectedItemTotalCount = 0;
    items[activeItemDataIndex].forEach((item) => {
      expectedItemTotalCount = expectedItemTotalCount + item.count;
    });

    let currentItemTotalCount = 0;
    items[activeItemDataIndex].forEach((item) => {
      item.selectedProductData &&
        item.selectedProductData.forEach((productData) => {
          currentItemTotalCount = currentItemTotalCount + productData.qty;
        });
    });

    return {
      stats: `${currentItemTotalCount}/${expectedItemTotalCount} items`,
      status: currentItemTotalCount / expectedItemTotalCount,
    };
  };

  const getAllItemPriceStats = (): { stats: string; status: number } => {
    let expectedItemTotalPrice = 0;
    items[activeItemDataIndex].forEach((item) => {
      expectedItemTotalPrice =
        (expectedItemTotalPrice + item.price) * item.count;
    });

    let currentItemTotalPrice = 0;
    items[activeItemDataIndex].forEach((item) => {
      item.selectedProductData &&
        item.selectedProductData.forEach((productData) => {
          currentItemTotalPrice =
            (currentItemTotalPrice + productData.price) * productData.qty;
        });
    });

    return {
      stats: `${formatAmountByCurrency(
        currentItemTotalPrice,
        currency
      )} / ${formatAmountByCurrency(expectedItemTotalPrice, currency)}`,
      status: currentItemTotalPrice / expectedItemTotalPrice,
    };
  };

  const getItemCountStats = (
    itemData: TItemData
  ): { stats: string; status: number } => {
    let expectedItemTotalCount = itemData.count;

    let currentItemTotalCount = 0;

    itemData.selectedProductData &&
      itemData.selectedProductData.forEach((productData) => {
        currentItemTotalCount = currentItemTotalCount + productData.qty;
      });

    return {
      stats: `(${currentItemTotalCount}/${expectedItemTotalCount})`,
      status: currentItemTotalCount / expectedItemTotalCount,
    };
  };

  return (
    <div className="picker-wrapper">
      <div className="picker-wrapper-title-row">
        <h4>{title}</h4>
        <div>
          <h6
            className={`${
              getAllItemCountStats().status === 1 &&
              "picker-wrapper-stats-complete"
            } ${
              getAllItemCountStats().status > 1 &&
              "picker-wrapper-stats-exceeded"
            }`}
          >
            {getAllItemCountStats().stats}
          </h6>
          <h6
            className={`${
              getAllItemPriceStats().status === 1 &&
              "picker-wrapper-stats-complete"
            } ${
              getAllItemPriceStats().status > 1 &&
              "picker-wrapper-stats-exceeded"
            }`}
          >
            {getAllItemPriceStats().stats}
          </h6>

          {showRoomDetails ? (
            <CgChevronUpO
              className="picker-wrapper-title-icon"
              onClick={() => setShowRoomDetails(false)}
            />
          ) : (
            <CgChevronDownO
              className="picker-wrapper-title-icon"
              onClick={() => setShowRoomDetails(true)}
            />
          )}
        </div>
      </div>

      <div className="picker-wrapper-draft-container">
        <DraftTabs items={items} activeItemDataIndex={activeItemDataIndex} />
        <div className="picker-wrapper-draft-items">
          {items[activeItemDataIndex].map((itemData, itemDataIndex) => (
            <div key={uuidv4()} className="picker-wrapper-draft-item">
              <div className="picker-wrapper-draft-item-header">
                <h5>
                  {itemData.item}{" "}
                  <span
                    className={`${
                      getItemCountStats(itemData).status === 1 &&
                      "picker-wrapper-stats-complete"
                    } ${
                      getItemCountStats(itemData).status > 1 &&
                      "picker-wrapper-stats-exceeded"
                    }`}
                  >
                    {getItemCountStats(itemData).stats}
                  </span>
                </h5>
                <FiSearch className="picker-wrapper-draft-item-search-icon" />
              </div>

              {showRoomDetails && (
                <div className="picker-wrapper-draft-item-details">
                  {itemData.selectedProductData?.map(
                    (productData, productDataIndex) => (
                      <PickerItemDetails
                        key={uuidv4()}
                        product={productData.product}
                        imageURL={
                          productData.images ? productData.images[0] : ""
                        }
                        qty={productData.qty}
                        onQtyChange={(v) =>
                          onSelectedProductQtyChange(
                            v,
                            itemDataIndex,
                            productDataIndex
                          )
                        }
                      />
                    )
                  )}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Picker;
