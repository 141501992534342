import React, { useState, useEffect } from "react";
import "./QuotePreviewPerRoom.css";
import QuotePreviewPerItem from "./QuotePreviewPerItem/QuotePreviewPerItem";
import CanvasDisplay from "../../../../../../Shared/Components/CanvasDisplay/CanvasDisplay";
import { v4 as uuidv4 } from "uuid";



const QuotePreviewPerRoom = (props) => {
  const [hasImages, setHasImages] = useState(false);

  useEffect(() => {
    props.quoteItems.forEach((quoteItem) => {
      quoteItem.items.forEach((item) => {
        if (item.images && item.images[0]) {
          setHasImages(true);
        }
      });
    });
  }, []);

  return (
    <div className="quote-preview-per-room-wrapper">
     
                            {props.designCanvasData && props.designCanvasData.map((canvasData, index) =>
      {
        if (canvasData.room === props.room.description)
        {
          return <CanvasDisplay key={index} id={uuidv4()} title={props.room.description.toUpperCase()} canvas={canvasData.canvas[canvasData.activeDraftIndex] }/>
        }
                            })}
      <div className="quote-preview-per-room-header">
        <div className="quote-preview-per-room-name">
          <h5>
            {props.room.qty ? props.room.qty : "1"}
            <small>&nbsp;&nbsp;&nbsp;&nbsp; X &nbsp;&nbsp;&nbsp;&nbsp;</small>
            {props.room.description.toUpperCase()}
          </h5>
          <p>{props.unit.fields["Item Name"]}</p>
        </div>
        <div className="quote-preview-per-room-details">
          <h5 className="quote-preview-per-room-item-name">Item Name</h5>
          <h5 className="quote-preview-per-room-quantity">Quantity</h5>
          {(props.paymentOption === "null" || true) &&
            // props.project.title === "Point Zero Immobilier deal" &&
            hasImages &&
            props.showImageInClientView && (
              <h5 className="quote-preview-per-room-amount">Amount</h5>
            )}
        </div>
        </div>
      <div className="quote-preview-per-room-items">
        {props.quoteItems.map((quoteItem, index) => {
          return (
            quoteItem.room.description === props.room.description &&
            // quoteItem.room.description !== "Service" &&
            quoteItem.items.map((item, index) => {
              return (
                item.fields["Sale Price (" + props.projectCurrency + ")"] >
                  0 && (
                  <QuotePreviewPerItem
                    key={index}
                    itemIndex={index}
                    item={item}
                    unitSKU={props.unit.fields.SKU}
                    paymentOption={props.paymentOption}
                    rentalPriceIdentifier={props.rentalPriceIdentifier}
                    projectCurrency={props.projectCurrency}
                    project={props.project}
                    showImageInClientView={props.showImageInClientView}
                    salesView={props.salesView}
                    handlePreviewImages={(e) =>
                      props.handlePreviewImages(e, item)
                    }
                  />
                )
              );
            })
          );
        })}
      </div>
      </div>
  );
};

export default QuotePreviewPerRoom;
