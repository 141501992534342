type TGetTotalNumberOfItems = (quoteData: any) => number;

const getTotalNumberOfItems: TGetTotalNumberOfItems = (quoteData) => {
  let totalNumberOfItems = 0;

  quoteData.forEach((data: any) => {
    data.quoteItems.forEach((quoteItem: any) => {
      quoteItem.items.forEach((item: any) => {
        totalNumberOfItems = totalNumberOfItems + 1;
      });
    });
  });

  return totalNumberOfItems;
};

export default getTotalNumberOfItems;
