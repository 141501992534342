import React, { useState } from "react";
import { BiChevronDown } from "react-icons/bi";
import { useSelector } from "react-redux";
import ListDropDown from "../../../../../Shared/Components/ListDropDown/ListDropDown";
import RangeSelector from "../../../../../Shared/Components/RangeSelector/RangeSelector";
import FilterActionButtons from "../FilterActionButtons/FilterActionButtons";
import "./FilterByPrice.scss";

type TReduxStateSelector = {
  quote: { quoteData: object[]; budget: number; projectCurrency: string };
};

type TFilterParams = {
  priceMin: number;
  priceMax: number;
  currency: string;
};

type Props = {
  filterLabel: string;
  filterData: {
    filterParams: TFilterParams;
  };
  customFilteredFilters: string[];
  handleCustomFilteredFilters: (customFilteredFilters: string[]) => void;
  onFilter: (filterData: object) => void;
  onCancel: (actionName: string) => void;
};

const FilterByPrice: React.FC<Props> = ({
  filterLabel,
  filterData,
  customFilteredFilters,
  handleCustomFilteredFilters,
  onFilter,
  onCancel,
}) => {
  const projectCurrency: any = useSelector<TReduxStateSelector>(
    (state) => state.quote.projectCurrency
  );

  const [filterParams, setFilterParams] = useState<TFilterParams>(
    filterData.filterParams
  );

  const handleCurrencySelect = (currency: string) => {
    let newFilterParams = { ...filterParams };
    newFilterParams.currency = currency;
    setFilterParams(newFilterParams);
  };

  const handlePriceSliderValues = (value: {
    minValue: number;
    maxValue: number;
  }) => {
    let newFilterParams = { ...filterParams };
    newFilterParams.priceMin = value.minValue;
    newFilterParams.priceMax = value.maxValue;
    setFilterParams(newFilterParams);
  };

  const handleActionButton = (actionName: string) => {
    let newCustomFilteredFilters = [...customFilteredFilters];
    let filterIndex = newCustomFilteredFilters.indexOf(filterLabel);
    if (filterParams.priceMin !== 0 || filterParams.priceMax !== 1000) {
      if (!(filterIndex > -1)) {
        newCustomFilteredFilters.push(filterLabel);
      }
    } else {
      if (filterIndex > -1) {
        newCustomFilteredFilters.splice(filterIndex, 1);
      }
    }

    handleCustomFilteredFilters(newCustomFilteredFilters);

    if (actionName === "apply") {
      let newFilterData = { ...filterData };
      newFilterData.filterParams = filterParams;

      console.log(newFilterData);
      onFilter(newFilterData);
    }

    onCancel(actionName);
  };

  return (
    <div className="filter-by-price-wrapper">
      <div className="filter-by-price-top-section">
        <div className="filter-by-price-top-section-header">
          <h5>Price</h5>
          <ListDropDown
            label={
              filterParams?.currency ? filterParams?.currency : projectCurrency
            }
            labelIsActive={true}
            suffixIcon={<BiChevronDown />}
            dropdownFloatDirection="right"
            listLabelClass="filter-by-item-type-top-right-section-selection-label"
            listItemClass="filter-by-item-type-top-right-section-selection-item"
            listItems={["CAD", "USD", "EUR", "GBP"]}
            onSelectItem={handleCurrencySelect}
          />
        </div>
        <div className="filter-by-price-range-section">
          <RangeSelector
            range={1000}
            minValue={filterParams.priceMin}
            maxValue={filterParams.priceMax}
            onChange={handlePriceSliderValues}
          />
        </div>
      </div>

      <FilterActionButtons onClick={handleActionButton} />
    </div>
  );
};

export default FilterByPrice;
