
import React from "react";
import "./OverlayButton.scss";


type Props = {
    icon: any;
  label: string;
    onClick:(event:React.MouseEvent)=>void
};

const OverlayButton: React.FC<Props> = ({ icon,label,onClick }) => {

  return (

            <div className="overlay-button-wrapper" onClick={onClick}>
 {icon}
      <h4>{label}</h4>
            </div>
  );
};

export default OverlayButton;
