import React from "react";
import { useSelector } from "react-redux";
import { saveAs } from "file-saver";
import JSZip from "jszip";
import JSZipUtils from "./jsziputils";
import "./SelectedImageDownloadModal.scss";

type TReduxStateSelector = {
  project: object;
  quotePickerData: any;
};

type Props = {
  onClose: () => void;
};

const SelectedImageDownloadModal: React.FC<Props> = ({ onClose }) => {
  const project: any = useSelector<TReduxStateSelector>(
    (state) => state.project
  );
  const quotePickerData: any = useSelector<TReduxStateSelector>(
    (state) => state.quotePickerData
  );

  const selectedPickerImages = () => {
    let selectedImages: string[] = [];

    quotePickerData?.forEach((pickerData: any) => {
      pickerData.forEach((picker: any) => {
        picker?.items?.forEach((itemData: any) => {
          itemData.forEach((item: any) => {
            item.selectedProductData?.forEach((productData: any) => {
              productData.images?.forEach((image: string) => {
                selectedImages.push(`//images.weserv.nl/?url=${image}`);
              });
            });
          });
        });
      });
    });

    return selectedImages;
  };

  const downloadImages = async () => {
    const zip = new JSZip();
    let count = 0;
    const zipFilename = `${project.title.toLowerCase()}-product-images.zip`;
    const urls = selectedPickerImages();

    urls.forEach((url, index) => {
      let filename = `file${index + 1}.jpg`;
      // loading a file and add it in a zip file

      JSZipUtils.getBinaryContent(url, (err: any, data: any) => {
        if (err) {
          throw err; // or handle the error
        }
        zip.file(filename, data, { binary: true });
        count++;
        if (count === urls.length) {
          zip.generateAsync({ type: "blob" }).then((content) => {
            saveAs(content, zipFilename);
          });
        }
      });
    });
  };

  return (
    <div className="selected-image-download-modal-wrapper">
      <h4>{`Sourced products images for ${project.title.toLowerCase()} `}</h4>
      <div className="selected-image-download-modal-images">
        {selectedPickerImages().map((imageURL, index) => (
          <img
            key={index}
            className="selected-image-download-modal-image"
            src={`//images.weserv.nl/?url=${imageURL}`}
            alt="selected picker product"
          />
        ))}
      </div>
      <div className="selected-image-download-modal-footer">
        <button onClick={downloadImages}>Download All</button>
      </div>
    </div>
  );
};

export default SelectedImageDownloadModal;
