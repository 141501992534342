import React, { useState } from "react";
import { useSelector } from "react-redux";
import CheckBox from "../../../../../Shared/Components/CheckBox/CheckBox";
import FilterActionButtons from "../FilterActionButtons/FilterActionButtons";
import "./FilterByVendors.scss";
import { v4 as uuidv4 } from "uuid";


type TReduxStateSelector = {
  productsData: { vendorsWithinRadius: object[] };
};

type Props = {
  filterLabel: string;
  filterData: { filterParams: { vendorName: string[] | null } };
  customFilteredFilters: string[];
  handleCustomFilteredFilters: (customFilteredFilters: string[]) => void;
  onFilter: (filterData: object) => void;
  onCancel: (actionName: string) => void;
};

const FilterByVendors: React.FC<Props> = ({
  filterLabel,
  filterData,
  customFilteredFilters,
  handleCustomFilteredFilters,
  onFilter,
  onCancel,
}) => {
  const vendorsData: any = useSelector<TReduxStateSelector>(
    (state) => state.productsData.vendorsWithinRadius
  );

  const [vendorNames, setVendorNames] = useState<string[] | null>(
    filterData.filterParams.vendorName
  );

  const generateActiveVendorRegions = (vendorData: object[]): string[] => {
    let activeVendorRegions: any = [];
    vendorData.forEach((data: any) => {
      if (!activeVendorRegions.includes(data.region)) {
        activeVendorRegions.push(data.region);
      }
    });

    return activeVendorRegions;
  };

  const handleVendorRadiusSliderValues = () => {};

  const handleVendorFilterByName = (value: boolean, vendorName: string) => {
    let newVendorNames: string[] | null = vendorNames;
    let allVendorNames = vendorsData.map((data: { name: string }) => data.name);
    if (!newVendorNames) newVendorNames = allVendorNames;

    newVendorNames =
      newVendorNames &&
      newVendorNames.filter((vendor: string) => vendor !== vendorName);

    if (newVendorNames?.length === allVendorNames.length) newVendorNames = null;

    if (value && newVendorNames) {
      newVendorNames.push(vendorName);
    }

    setVendorNames(newVendorNames);
  };

  const handleActionButton = (actionName: string) => {
    let newCustomFilteredFilters = [...customFilteredFilters];
    let filterIndex = newCustomFilteredFilters.indexOf(filterLabel);
    if (vendorNames) {
      if (!(filterIndex > -1)) {
        newCustomFilteredFilters.push(filterLabel);
      }
    } else {
      if (filterIndex > -1) {
        newCustomFilteredFilters.splice(filterIndex, 1);
      }
    }

    handleCustomFilteredFilters(newCustomFilteredFilters);

    if (actionName === "apply") {
      let newFilterData = { ...filterData };
      newFilterData.filterParams.vendorName = vendorNames;
      onFilter(newFilterData);
    }

    onCancel(actionName);
  };

  return (
    <div className="filter-by-vendors-wrapper">
      <div className="filter-by-vendors-top-section">
        {/* <div className="filter-by-vendors-top-left-section">
          <h5>Radius</h5>
          <RangeSelector
            range={1500}
            // minValue={0}
            // maxValue={100}
            onChange={handleVendorRadiusSliderValues}
          />
        </div> */}
        {/* <div className="filter-by-vendors-top-mid-section">
          <h5>Region</h5>
          <div className="filter-by-vendors-top-mid-section-vendor-regions">
            {vendorsData &&
              generateActiveVendorRegions(vendorsData).map((region: any) => (
                <div
                  key={uuidv4()}
                  className="filter-by-vendors-top-mid-section-vendor-region"
                >
                  <CheckBox isChecked={true} label={region} />
                </div>
              ))}
          </div>
        </div> */}
        <div className="filter-by-vendors-top-right-section">
          <h5>Vendors</h5>
          <div className="filter-by-vendors-top-right-section-vendors">
            <div className="filter-by-vendors-top-right-section-vendor">
              <CheckBox
                isChecked={!vendorNames || !(vendorNames.length >= 0)}
                label={`All(${vendorsData.length})`}
                onClick={(v) => (v ? setVendorNames(null) : setVendorNames([]))}
              />
            </div>
            {vendorsData &&
              vendorsData.map((data: { name: string, address: string }) => (
                <div
                  key={uuidv4()}
                  className="filter-by-vendors-top-right-section-vendor"
                >
                  <CheckBox
                    isChecked={
                      !vendorNames ||
                      (vendorNames && vendorNames.indexOf(data.name) > -1)
                    }
                    label={data.name}
                    onClick={(v) => handleVendorFilterByName(v, data.name)}
                  />
                  {data.address}
                </div>
              ))}
          </div>
        </div>
      </div>

      <FilterActionButtons onClick={handleActionButton} />
    </div>
  );
};

export default FilterByVendors;
