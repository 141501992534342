import React, { useState } from "react";
import "./PickerItemDetails.scss";
import { AiOutlineMinusCircle } from "react-icons/ai";
import { AiOutlinePlusCircle } from "react-icons/ai";
import OverlayButton from "../../../../../Shared/Components/OverlayButton/OverlayButton";
import { FaClipboardList, FaTrashAlt } from "react-icons/fa";
import { GoInfo } from "react-icons/go";
import ProductDetailsModal from "../../../../../Shared/Components/ProductDetailsModal/ProductDetailsModal";
import { TSelectedProductData } from "../../../../../Shared/Types/PickerTypes";
import { TItemQuantityValue } from "../../../../../Shared/Types/ItemQuantityValue";
import PageModal from "../../../../../Shared/Components/PageModal/PageModal";
import PickerImagesClipboardModal from "../PickerImagesClipboardModal/PickerImagesClipboardModal";

type Props = {
  imageURL: string;
  qty: number;
  product: any;
  onQtyChange: (value: TItemQuantityValue) => void;
};

const PickerItemDetails: React.FC<Props> = ({
  imageURL,
  qty,
  product,
  onQtyChange,
}) => {
  const [showProductDetails, setShowProductDetails] = useState<boolean>(false);
  const [showClipBoard, setShowClipBoard] = useState<boolean>(false);

  return (
    <React.Fragment>
      <div className="picker-item-details-wrapper">
        <div
          className="picker-item-details-image"
          style={{
            backgroundImage: `url(//images.weserv.nl/?url=${imageURL})`,
          }}
        >
          <div className="picker-item-details-image-overlay">
            <OverlayButton
              icon={<FaClipboardList />}
              label="Copy to Clipboard"
              onClick={() => setShowClipBoard(true)}
            />
            <OverlayButton
              icon={<GoInfo />}
              label="Details"
              onClick={() => setShowProductDetails(true)}
            />
            <OverlayButton
              icon={<FaTrashAlt />}
              label="Delete"
              onClick={() => onQtyChange("delete")}
            />
          </div>
        </div>
        <div className="picker-item-details-qty">
          <span>Qty:</span>
          <AiOutlineMinusCircle
            className="picker-item-details-qty-icon"
            onClick={() => onQtyChange("decrement")}
          />
          <span>{qty}</span>
          <AiOutlinePlusCircle
            className="picker-item-details-qty-icon"
            onClick={() => onQtyChange("increment")}
          />
        </div>
      </div>
      <ProductDetailsModal
        product={product}
        show={showProductDetails}
        onClose={() => setShowProductDetails(false)}
      />
      <PageModal
        visible={showClipBoard}
        component={<PickerImagesClipboardModal imageURLs={product.imageURLs} />}
        onClose={() => setShowClipBoard(false)}
      />
    </React.Fragment>
  );
};

export default PickerItemDetails;
