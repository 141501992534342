import React, {useState} from "react";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import "./RoomsMenu.scss";
import { v4 as uuidv4 } from "uuid";


type TMenu = { room: string, categories: string[] }[]
type TActiveMenu = {room:string, categories: string[]}


type Props = {
  roomsAndCategories: TMenu;
  handleSelectedCategory: (room: string, category:string) => void;
};

const RoomsMenu: React.FC<Props> = ({ roomsAndCategories, handleSelectedCategory }) =>
{
  
  const [activeMenu, setActiveMenu] = useState<TActiveMenu | null>(null);




  return <div className="room-menu-wrapper">
    {!activeMenu ? <React.Fragment>
      {roomsAndCategories.map((menu: TActiveMenu) => <div key={uuidv4()} className="room-menu-label" onClick={(e) => setActiveMenu(menu)}>
        <h5>{menu.room}</h5>
        <FiChevronRight className="room-menu-icon"/>
      </div>)}
    </React.Fragment> :
      <React.Fragment>
        <div className="room-menu-label-category" onClick={(e) => setActiveMenu(null)}>
        <FiChevronLeft className="room-menu-icon" style={{marginLeft: "-3px"}} />
        <h5>{activeMenu.room}</h5>
        </div>

        {activeMenu.categories.map((category: string) => <div key={uuidv4()}   onClick={(e) => handleSelectedCategory(activeMenu.room, category)}>
          <h6>{category}</h6>
        </div>)}
      </React.Fragment>}
  </div>
}

export default RoomsMenu;
