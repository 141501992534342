import React, { useState, useEffect, useRef } from "react";
import NavBar from "../../Shared/Components/NavBar/NavBar";
import Demarcator from "./Demarcator/Demarcator";
import DesignCanvas from "./DesignCanvas/DesignCanvas";
import DesignCatalogue from "./DesignCatalogue/DesignCatalogue";
import "./DesignPage.scss";
import Selector from "./Selector/Selector";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import fetchRequest from "../../Shared/Functions/fetchRequest";
import { SpinnerCircular } from "spinners-react";
import { FaCompressArrowsAlt, FaExpandArrowsAlt } from "react-icons/fa";
import DesignItemPicker from "./DesignItemPicker/DesignItemPicker";
import generatePickerData from "../../Shared/Functions/generatePickerData";
import generateRooms from "../../Shared/Functions/generateRooms";

type TReduxStateSelector = {
  finalizeMode: boolean;
};

type Props = {
  props: any;
};

const DesignPage: React.FC<Props> = React.memo(({ props }) => {
  const [fullScreen, setFullScreen] = useState<boolean>(false);
  const [designPageBodyLeftSectionWidth, setDesignPageBodyLeftSectionWidth] =
    useState<number | null>(null);

  const [selectedTabName, setSelectedTabName] = useState<string>("Canvas");
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const location = useLocation();
  const dispatch = useDispatch();
  const finalizeMode = useSelector<TReduxStateSelector>(
    (state) => state.finalizeMode
  );

  useEffect(() => {
    if (finalizeMode) {
      setIsLoading(false);
      dispatch({ type: "finalizeMode", value: false });
      return;
    }
    setIsLoading(true);
    const projectName = new URLSearchParams(location.search).get("project");
    if (!projectName) {
      console.log({ projectName });
      return;
    }

    const initializeData = async () => {
      const project = await fetchRequest(
        `/pipedrive/projects/${projectName}`,
        "GET"
      );

      if (!project.success) {
        setIsLoading(false);
        return;
      }
      dispatch({ type: "project", value: project.project });

      const quote = await fetchRequest(`/quotes/project/${projectName}`, "GET");
      if (!quote.success) {
        setIsLoading(false);
        return;
      }
      dispatch({ type: "quote", value: quote.data });
      dispatch({ type: "activeUnitIndex", value: 0 });
      dispatch({
        type: "activeUnit",
        value: quote.data.quoteData[0].unit.fields["Item Name"],
      });
      dispatch({
        type: "selectedRoom",
        value: generateRooms(quote.data.quoteData[0])[0],
      });

      //Generate default design data
      dispatch({
        type: "picker",
        value: generatePickerData(
          quote.data.quoteData,
          0,
          quote.data.projectCurrency
        ).activeUnitPickerData,
      });

      dispatch({
        type: "quotePickerData",
        value: generatePickerData(
          quote.data.quoteData,
          0,
          quote.data.projectCurrency
        ).pickerData,
      });

      const designDataResponse = await fetchRequest(
        `/design-builder/quote/${quote.data.quoteID.quoteID}`,
        "GET"
      );

      if (!designDataResponse.success) {
        setIsLoading(false);

        return;
      }

      console.log(designDataResponse);
      // dispatch({
      //   type: "picker",
      //   value: [
      //     ...generatePickerData(
      //       quote.data.quoteData,
      //       0,
      //       project.project.currency
      //     ),
      //     designDataResponse.data.designData.picker,
      //   ],
      // });

      dispatch({ type: "picker", value: designDataResponse.data.picker[0] });
      dispatch({
        type: "quotePickerData",
        value: designDataResponse.data.picker,
      });
      dispatch({
        type: "designData",
        value: designDataResponse.data.designData,
      });
      dispatch({
        type: "designCanvas",
        value: designDataResponse.data.designCanvas,
      });
      dispatch({
        type: "revisions",
        value: designDataResponse.revisions,
      });
      setIsLoading(false);

      // const designDataResponse = await getDesignData(
      //   data.quote.quoteID.quoteID
      // );

      // if (!designDataResponse) {
      //   props.dispatch({
      //     type: "initialize-moodboardData",
      //     value: {},
      //     revisionNum: 1,
      //   });
      // }

      // const designStylesResponse = await getDesignStyles();
      // if (!designStylesResponse) {
      //   return;
      // }

      // const getProjectFilesResponse = await getProjectFiles();
      // if (!getProjectFilesResponse) {
      //   return;
      // }

      // const getProjectNotesResponse = await getProjectNotes();
      // if (!getProjectNotesResponse) {
      //   return;
      // }

      // const getDesignElementCategoriesResponse = await getDesignElementCategories();
      // if (!getDesignElementCategoriesResponse) {
      //   return;
      // }

      // props.history.push("/units");
    };

    initializeData();
  }, []);

  const handleFullScreen = () => {
    const fullScreenState = fullScreen;
    setFullScreen(!fullScreenState);
  };

  const getResponsiveWidthBasedOnStaticWidth = (staticWidth: number) => {
    return (staticWidth / window.innerWidth) * 100;
  };

  return isLoading ? (
    <div className="design-page-body-spinner">
      <SpinnerCircular secondaryColor="#bdbdbd" color="#ff4e24" />
    </div>
  ) : (
    <div className="design-page-wrapper">
      <NavBar />
      <div className="design-page-body">
        <div
          className="design-page-body-left-section"
          style={
            fullScreen
              ? { width: "100%" }
              : designPageBodyLeftSectionWidth
              ? {
                  width: `${getResponsiveWidthBasedOnStaticWidth(
                    designPageBodyLeftSectionWidth
                  )}%`,
                }
              : {}
          }
        >
          <div className="design-page-body-left-section-header-row">
            <Selector
              selectors={["Catalogue", "Canvas"]}
              selectorWidth="calc(150px + 20%)"
              handleSelection={(s) => setSelectedTabName(s)}
            />
            {!fullScreen && (
              <FaExpandArrowsAlt
                className="design-page-body-left-section-header-expand-icon"
                onClick={handleFullScreen}
              />
            )}
            {fullScreen && (
              <FaCompressArrowsAlt
                className="design-page-body-left-section-header-expand-icon"
                onClick={handleFullScreen}
              />
            )}
          </div>

          <div className="design-page-body-left-section-tabs">
            {selectedTabName === "Canvas" && <DesignCanvas />}
            {selectedTabName === "Catalogue" && <DesignCatalogue />}
          </div>
        </div>
        {!fullScreen && (
          <React.Fragment>
            <Demarcator
              onXPositionChange={(v) => setDesignPageBodyLeftSectionWidth(v)}
            />
            <div className="design-page-body-right-section">
              <DesignItemPicker />
            </div>
          </React.Fragment>
        )}
      </div>
    </div>
  );
});

export default DesignPage;
