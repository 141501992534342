import React, { useEffect, useState } from "react";
import { BsArrowRepeat } from "react-icons/bs";
import { TDesignElementDataType } from "../../../Types/DesignElementDataType";
import "./PlayGroundElement.scss";

type Props = {
  dpeID: string;
  elementData: TDesignElementDataType;
  onChange: (elementData: TDesignElementDataType) => void;
};

const PlayGroundElement: React.FC<Props> = ({
  dpeID,
  elementData,
  onChange,
}) => {
  const [elementStyle, setElementStyle] = useState<{
    [key: string]: string | undefined;
  }>({ opacity: "1" });
  const [elementSelected, setElementSelected] = useState<boolean>(false);
  const [disableRotate, setDisableRotate] = useState<boolean>(false);

  useEffect(() => {
    const elementMetaData: any = elementData.metaData;
    const elementMetaDataKeys = Object.keys(elementMetaData);
    let style: { [key: string]: string } = {};
    elementMetaDataKeys.forEach((key) => {
      if (elementMetaData[key]) {
        style[key] = elementMetaData[key] + "px";
      }
    });
    setElementStyle({ ...style, opacity: "1" });
  }, []);

  const dragElement = (event: React.MouseEvent) => {
    setDisableRotate(true);
    const element = document.getElementById(dpeID);
    let pos1 = 0,
      pos2 = 0,
      pos3 = 0,
      pos4 = 0;
    if (element) {
      const stopElementDrag = () => {
        setDisableRotate(false);
        // stop moving when mouse button is released:
        document.onmouseup = null;
        document.onmousemove = null;
        const copyOfElementData = { ...elementData };
        copyOfElementData.metaData = {
          ...elementData.metaData,
          top: element.offsetTop - pos2,
          left: element.offsetLeft - pos1,
        };
        onChange(copyOfElementData);
      };

      const elementDrag = (event: MouseEvent) => {
        event.preventDefault();
        // calculate the new cursor position:
        pos1 = pos3 - event.clientX;
        pos2 = pos4 - event.clientY;
        pos3 = event.clientX;
        pos4 = event.clientY;
        // set the element's new position:
        element.style.top = element.offsetTop - pos2 + "px";
        element.style.left = element.offsetLeft - pos1 + "px";
      };

      event.preventDefault();
      // get the mouse cursor position at startup:
      pos3 = event.clientX;
      pos4 = event.clientY;
      document.onmouseup = stopElementDrag;
      // call a function whenever the cursor moves:
      document.onmousemove = elementDrag;
    }
  };

  type TResizeDirection =
    | "up"
    | "right"
    | "down"
    | "left"
    | "top-left"
    | "top-right"
    | "bottom-right"
    | "bottom-left";

  const resizeElement = (
    event: React.MouseEvent,
    resizeDirection: TResizeDirection
  ) => {
    setDisableRotate(true);
    const element = document.getElementById(dpeID);

    if (element) {
      const currentHeight = element.offsetHeight;
      const currentWidth = element.offsetWidth;
      const currentTopPosition = element.offsetTop;
      const currentLeftPosition = element.offsetLeft;
      let currentPageX: number | null = null;
      let currentPageY: number | null = null;
      let leftOffsetDifference: number = 0;
      let topOffsetDifference: number = 0;
      let newHeight = currentHeight;
      let newWidth = currentWidth;
      let newTopPosition = currentTopPosition;
      let newLeftPosition = currentLeftPosition;

      const stopResize = () => {
        setDisableRotate(false);

        // stop moving when mouse button is released:
        document.onmouseup = null;
        document.onmousemove = null;
        const copyOfElementData = { ...elementData };
        copyOfElementData.metaData = {
          top: newTopPosition,
          left: newLeftPosition,
          height: newHeight,
          width: newWidth,
        };
        onChange(copyOfElementData);
      };

      const resize = (event: MouseEvent) => {
        const fixedWidth = () => {
          element.style.width = currentWidth + "px";
        };

        const fixedHeight = () => {
          element.style.height = currentHeight + "px";
        };

        const resizeWidthRightward = () => {
          const resizedWidth =
            event.pageX - element.getBoundingClientRect().left;
          element.style.width = resizedWidth + "px";
          newWidth = resizedWidth;
        };

        const resizeWidthRightwardResponsive = () => {
          const resizedWidth = (currentWidth * newHeight) / currentHeight;
          element.style.width = resizedWidth + "px";
          newWidth = resizedWidth;
        };

        const resizeWidthLeftward = () => {
          if (!currentPageX) {
            currentPageX = event.pageX;
            leftOffsetDifference = Math.abs(currentLeftPosition - event.pageX);
          }

          const resizedLeftPosition = event.pageX - leftOffsetDifference;
          const resizedWidth = currentPageX - event.pageX + currentWidth;
          element.style.left = resizedLeftPosition + "px";
          element.style.width = resizedWidth + "px";
          newLeftPosition = resizedLeftPosition;
          newWidth = resizedWidth;
        };

        const resizeWidthLeftwardResponsive = () => {
          const adjustedWidth = (currentWidth * newHeight) / currentHeight;
          const resizedLeftPosition = Math.abs(
            currentLeftPosition + currentWidth - adjustedWidth
          );
          const resizedWidth = adjustedWidth;
          element.style.left = resizedLeftPosition + "px";
          element.style.width = resizedWidth + "px";
          newLeftPosition = resizedLeftPosition;
          newWidth = resizedWidth;
        };

        const resizeHeightDownward = () => {
          const resizedHeight =
            event.pageY - element.getBoundingClientRect().top;
          element.style.height = resizedHeight + "px";
          newHeight = resizedHeight;
        };

        const resizeHeightUpward = () => {
          if (!currentPageY) {
            currentPageY = event.pageY;
            topOffsetDifference = Math.abs(currentTopPosition - event.pageY);
          }
          const resizedHeight = currentPageY - event.pageY + currentHeight;
          const resizedTopPosition = event.pageY - topOffsetDifference;
          element.style.height = resizedHeight + "px";
          element.style.top = resizedTopPosition + "px";
          newHeight = resizedHeight;
          newTopPosition = resizedTopPosition;
        };

        if (resizeDirection === "right") {
          resizeWidthRightward();
          fixedHeight();
        }
        if (resizeDirection === "left") {
          resizeWidthLeftward();
          fixedHeight();
        }
        if (resizeDirection === "down") {
          resizeHeightDownward();
          fixedWidth();
        }
        if (resizeDirection === "up") {
          resizeHeightUpward();
          fixedWidth();
        }

        if (resizeDirection === "top-right") {
          resizeWidthRightwardResponsive();
          // resizeWidthRightward();
          resizeHeightUpward();
        }

        if (resizeDirection === "top-left") {
          resizeWidthLeftwardResponsive();
          // resizeWidthLeftward();
          resizeHeightUpward();
        }

        if (resizeDirection === "bottom-right") {
          resizeWidthRightwardResponsive();
          // resizeWidthRightward();
          resizeHeightDownward();
        }

        if (resizeDirection === "bottom-left") {
          resizeWidthLeftwardResponsive();
          // resizeWidthLeftward();
          resizeHeightDownward();
        }
      };

      document.onmousemove = resize;
      document.onmouseup = stopResize;
    }
  };

  const rotateElement = () => {
    var element = document.getElementById(dpeID);
    if (element) {
      var elementRects = element.getBoundingClientRect();
      var elementX = elementRects.left + elementRects.width / 2;
      var elementY = elementRects.top + elementRects.height / 2;

      const stopRotate = () => {
        setDisableRotate(false);

        // stop moving when mouse button is released:
        document.onmouseup = null;
        document.onmousemove = null;
        // const copyOfElementData = { ...elementData }
        // copyOfElementData.metaData = {top: newTopPosition, left: newLeftPosition, height: newHeight, width: newWidth}
        // onChange(copyOfElementData)
      };

      const rotate = (event: MouseEvent) => {
        setDisableRotate(true);
        if (element) {
          element.style.transform =
            "rotate(" +
            Math.atan2(event.clientY - elementY, event.clientX - elementX) +
            "rad)";
        }
      };

      document.onmousemove = rotate;
      document.onmouseup = stopRotate;
    }
  };

  const toggleElementSelect = (select: boolean = true) => {
    const isElementSelected = !elementSelected;
    setElementSelected(isElementSelected);
    const copyOfElementStyle = { ...elementStyle };
    if (copyOfElementStyle?.border) {
      copyOfElementStyle.border = undefined;
    }

    if (isElementSelected)
      setElementStyle({ ...copyOfElementStyle, border: "1px solid #003941" });
    if (!isElementSelected || select)
      setElementStyle({ ...copyOfElementStyle });
  };

  const handleDoubleClickedElement = () => {
    toggleElementSelect(false);
  };

  const handleClickElement = () => {
    toggleElementSelect();
  };

  return (
    <React.Fragment>
      <div
        id={dpeID}
        className="design-playground-element-wrapper"
        style={elementStyle}
        onDoubleClick={handleDoubleClickedElement}
        onClick={handleClickElement}
      >
        <div id={`${dpeID}-ol`} className="design-playground-element-overlay">
          <div
            className="design-playground-element-drag-area"
            onMouseDown={dragElement}
          ></div>
          {/* horizontal and vertical resize */}
          <div
            className={`${dpeID}-resize-handle design-playground-element-top-handle`}
            onMouseDown={(e) => resizeElement(e, "up")}
          ></div>
          <div
            className={`${dpeID}-resize-handle design-playground-element-right-handle`}
            onMouseDown={(e) => resizeElement(e, "right")}
          ></div>
          <div
            className={`${dpeID}-resize-handle design-playground-element-bottom-handle`}
            onMouseDown={(e) => resizeElement(e, "down")}
          ></div>
          <div
            className={`${dpeID}-resize-handle design-playground-element-left-handle`}
            onMouseDown={(e) => resizeElement(e, "left")}
          ></div>

          {/* angular resize */}
          <div
            className="design-playground-element-top-left-corner"
            onMouseDown={(e) => resizeElement(e, "top-left")}
          ></div>
          <div
            className="design-playground-element-top-right-corner"
            onMouseDown={(e) => resizeElement(e, "top-right")}
          ></div>
          <div
            className="design-playground-element-bottom-right-corner"
            onMouseDown={(e) => resizeElement(e, "bottom-right")}
          ></div>
          <div
            className="design-playground-element-bottom-left-corner"
            onMouseDown={(e) => resizeElement(e, "bottom-left")}
          ></div>

          {/* rotate */}
          {!disableRotate && (
            <div
              className="design-playground-element-top-right-corner-rotate"
              onMouseDown={(e) => rotateElement()}
            >
              <BsArrowRepeat className="design-playground-element-top-right-corner-rotate-icon" />
            </div>
          )}
        </div>

        {/* image */}
        <img
          width="100%"
          height="100%"
          src={`//images.weserv.nl/?url=${elementData.url}`}
          alt="img-element"
        ></img>
      </div>
    </React.Fragment>
  );
};

export default React.memo(PlayGroundElement);
