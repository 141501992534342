type TGetTotalNumberOfItems = (unitData: any) => number;

const getTotalNumberOfActiveUnitItems: TGetTotalNumberOfItems = (unitData) => {
  let totalNumberOfActiveUnitItems = 0;

  unitData.quoteItems.forEach((quoteItem: any) => {
    quoteItem.items.forEach((item: any) => {
      totalNumberOfActiveUnitItems = totalNumberOfActiveUnitItems + 1;
    });
  });

  return totalNumberOfActiveUnitItems;
};

export default getTotalNumberOfActiveUnitItems;
