// type TSelectedProductData = {images:string[] | null, price:number, qty:number}
// type TItemData = { item: string, count: number, price:number, selectedProductData:TSelectedProductData[] | null, }

import { TPickerData } from "../Types/PickerTypes";

// type TPickerData = {
//   room: string;
//   currency: string;
//     activeItemDataIndex: number;
//   items: TItemData[][];
// };

const generateUnitPickerData = (
  quoteData: any,
  activeIndex: number = 0,
  currency: string
) => {
  const unitPickerData = quoteData[activeIndex].quoteItems.map(
    (quoteItem: any) => {
      return {
        room: quoteItem.room.description,
        currency: currency,
        activeItemDataIndex: 0,

        items: [
          quoteItem.items.map((item: any) => {
            return {
              item: item.fields["Item Name"],
              count: item.fields[quoteData[activeIndex].unit.fields.SKU],
              price: item.fields[`Sale Price (${currency})`],
              selectedProductData: null,
            };
          }),
        ],
      };
    }
  );

  return unitPickerData;
};

const generatePickerData = (
  quoteData: any,
  activeIndex: number = 0,
  currency: string
) => {
  // const pickerData = quoteData.map((quote: any, index:number) =>
  // {

  //   return {
  //       unitSKU: quote.unit.fields.SKU,
  //       unitName: quote.unit.fields["Item Name"],
  //       pickerData: generateUnitPickerData(quoteData, index, currency)
  //     }

  // })
  let pickerData: TPickerData[][] = [];

  quoteData.forEach((quote: any) => {
    const unitSKU = quote.unit.fields.SKU;
    const unitQty = quote.unit?.qty ? quote.unit?.qty : 1;
    let unitPickerData: TPickerData[] = [];
    quote.quoteItems.forEach((quoteItem: any) => {
      const roomQty = quoteItem.room?.qty ? quoteItem.room?.qty : 1;
      unitPickerData.push({
        room: quoteItem.room.description,
        currency: currency,
        activeItemDataIndex: 0,

        items: [
          quoteItem.items.map((item: any) => {
            return {
              item: item.fields["Item Name"],
              count: item.fields[unitSKU] * unitQty * roomQty,
              price: item.fields[`Sale Price (${currency})`],
              selectedProductData: null,
            };
          }),
        ],
      });
    });
    pickerData.push(unitPickerData);
  });

  // const pickerData = quoteData[activeIndex].quoteItems.map((quoteItem: any) =>
  //      {

  //     return {
  //       room: quoteItem.room.description,
  //       currency: currency,
  //       activeItemDataIndex: 0,

  //       items: [quoteItem.items.map((item: any) =>
  //       {
  //         return {
  //           item: item.fields["Item Name"],
  //           count: item.fields[quoteData[activeIndex].unit.fields.SKU],
  //           price: item.fields[`Sale Price (${currency})`],
  //           selectedProductData: null
  //         }
  //       })]
  //     }
  //      })

  return { pickerData, activeUnitPickerData: pickerData[activeIndex] };
};

export default generatePickerData;
