import React from "react";
import Line from "../../../Shared/Components/Line/Line";
import "./Demarcator.scss";
import GhostIcon from "../../../../Icons/ghost.png";

type Props = {
  onXPositionChange: (position: number) => void;
};

const Demarcator: React.FC<Props> = ({onXPositionChange }) => {
  const createDemarcatorGhostImage = (event: React.DragEvent) => {
    event.dataTransfer.setData("text/plain", (event.target as any).id);
    let img = new Image();
    img.src = GhostIcon;
    img.style.display = "none";
    event.dataTransfer.setDragImage(img, 10, 10);
  };

  const handleDemarcatorDragStart = (event: React.DragEvent) => {
    createDemarcatorGhostImage(event);
  };

  const handleDemarcatorDrag = (event: React.DragEvent) => {
    createDemarcatorGhostImage(event);
    if (event.clientX !== 0) onXPositionChange(event.clientX);
  };

  const handleDemarcatorDragEnd = (event: React.DragEvent) => {
    console.log("end");
    
  };

  return (
    <div
      id="demarcator"
      className="demarcator-wrapper"
      draggable={true}
      onDragStart={handleDemarcatorDragStart}
      onDrag={handleDemarcatorDrag}
      onDragEnd={handleDemarcatorDragEnd}
      onDragOver={(e) => e.preventDefault()}
    >
      <Line type="vertical" width="3px" />
    </div>
  );
};

export default Demarcator;
