import React, { useState } from "react";
import BackgroundImage from "../BackgroundImage/BackgroundImage";
import Logo from "../../../../Logo/logo.png";
import "./NavBar.scss";
import { FaRegSave } from "react-icons/fa";
import { GoInfo } from "react-icons/go";
import "../../Classes/Button.scss";
import { useDispatch, useSelector } from "react-redux";
import ListDropDown from "../ListDropDown/ListDropDown";
import { BiChevronDown } from "react-icons/bi";
import { useHistory, useLocation } from "react-router-dom";
import lodash from "lodash";
import generateRooms from "../../Functions/generateRooms";
import { BsImages } from "react-icons/bs";
import PageModal from "../PageModal/PageModal";
import SelectedImageDownloadModal from "./SelectedImageDownloadModal/SelectedImageDownloadModal";

type Props = {
  showInfoButton?: boolean;
  showSaveButton?: boolean;
  showFinalizeButton?: boolean;
};

type TReduxStateSelector = {
  project: object;
  quote: { quoteData: object[]; budget: number; projectCurrency: string };
  activeUnitIndex: number;
  quotePickerData: any;
  picker: any;
};

const NavBar: React.FC<Props> = ({
  showInfoButton = true,
  showSaveButton = true,
  showFinalizeButton = true,
}) => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const project: any = useSelector<TReduxStateSelector>(
    (state) => state.project
  );

  const quoteData: any = useSelector<TReduxStateSelector>(
    (state) => state.quote.quoteData
  );

  const activeUnitIndex: any = useSelector<TReduxStateSelector>(
    (state) => state.activeUnitIndex
  );

  const quotePickerData: any = useSelector<TReduxStateSelector>(
    (state) => state.quotePickerData
  );

  const picker: any = useSelector<TReduxStateSelector>((state) => state.picker);

  const [showSelectedImages, setShowSelectedImages] = useState<boolean>(false);

  const onChangeUnit = (unitName: string) => {
    const copyOfQuotePickerData = lodash.cloneDeep(quotePickerData);
    copyOfQuotePickerData[activeUnitIndex] = picker;

    quoteData.forEach((quote: any, index: number) => {
      if (quote.unit.fields["Item Name"] === unitName) {
        dispatch({ type: "activeUnitIndex", value: index });
        dispatch({
          type: "activeUnit",
          value: unitName,
        });

        dispatch({
          type: "selectedRoom",
          value: generateRooms(quote)[0],
        });

        dispatch({
          type: "quotePickerData",
          value: copyOfQuotePickerData,
        });

        dispatch({
          type: "picker",
          value: quotePickerData[index],
        });
      }
    });
  };

  const handleFinalize = () => {
    history.push(`/finalize${location.search}`);
  };

  return (
    <React.Fragment>
      <div className="navbar-wrapper">
        <div className="navbar-left-section">
          <div className="navbar-logo">
            <BackgroundImage
              imageURL={Logo}
              width="auto"
              height="calc(15px + 2vw)"
            />
          </div>
          <div className="navbar-title">
            <div className="navbar-title-unit-row">
              <h3>{`${project.title} / `}</h3>
              {quoteData.length > 1 ? (
                <ListDropDown
                  label={quoteData[activeUnitIndex].unit.fields["Item Name"]}
                  listLabelClass="navbar-unit-selector-label"
                  suffixIcon={<BiChevronDown />}
                  listItems={quoteData.map(
                    (quote: { unit: any }) => quote.unit.fields["Item Name"]
                  )}
                  onSelectItem={onChangeUnit}
                />
              ) : (
                <span>
                  {quoteData[activeUnitIndex].unit.fields["Item Name"]}
                </span>
              )}
            </div>

            <h5>
              <span>Style: </span>
              {quoteData[activeUnitIndex].designStyles[0]?.fields["Name"]}
            </h5>
          </div>
        </div>

        <div className="navbar-right-section">
          <div className="navbar-action-buttons-wrapper">
            <BsImages
              className="navbar-action-icon"
              onClick={() => setShowSelectedImages(true)}
            />
            {showInfoButton && <GoInfo className="navbar-action-icon" />}
            {showSaveButton && <FaRegSave className="navbar-action-icon" />}
            {showFinalizeButton && (
              <button
                className="button navbar-finalize-button"
                onClick={handleFinalize}
              >
                Finalize
              </button>
            )}
          </div>
        </div>
      </div>
      {showSelectedImages && (
        <PageModal
          visible={showSelectedImages}
          component={
            <SelectedImageDownloadModal
              onClose={() => setShowSelectedImages(false)}
            />
          }
          onClose={() => setShowSelectedImages(false)}
        />
      )}
    </React.Fragment>
  );
};

export default NavBar;
