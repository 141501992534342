import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import NavBar from "../../Shared/Components/NavBar/NavBar";
import ClientView from "./ClientView/ClientView";
import "./FinalizePage.scss";
import { SpinnerCircular } from "spinners-react";
import { TPickerData } from "../../Shared/Types/PickerTypes";
import { TDesignDataType } from "../../Shared/Types/DesignDataType";
import { TDesignCanvasDataType } from "../../Shared/Types/DesignCanvasDataType";
import PageModal from "../../Shared/Components/PageModal/PageModal";
import SuccessModal from "./SuccessModal/SuccessModal";
import fetchRequest from "../../Shared/Functions/fetchRequest";

type TReduxStateSelector = {
  quotePickerData: any;
  quote: any;
  designData: TDesignDataType[];
  designCanvasData: TDesignCanvasDataType[];
};

const FinalizePage = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const quotePickerData: any = useSelector<TReduxStateSelector>(
    (state) => state.quotePickerData
  );

  const quote: any = useSelector<TReduxStateSelector>((state) => state.quote);

  const designData: any = useSelector<TReduxStateSelector>(
    (state) => state.designData
  );

  const designCanvasData: any = useSelector<TReduxStateSelector>(
    (state) => state.designCanvasData
  );

  const dispatch = useDispatch();

  const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false);

  useEffect(() => {
    setIsLoading(true);
    dispatch({ type: "finalizeMode", value: true });

    const updateQuoteImages = () => {
      const copyOfQuote = { ...quote };

      quotePickerData.forEach((pickerData: any) => {
        pickerData.forEach((pData: TPickerData) => {
          pData.items[pData.activeItemDataIndex].forEach((pickerItem) => {
            if (pickerItem.selectedProductData) {
              copyOfQuote.quoteData.forEach((quoteData: any) => {
                quoteData.quoteItems.forEach((quoteItem: any) => {
                  if (quoteItem.room.description === pData.room) {
                    quoteItem.items.forEach((item: any) => {
                      if (item.fields["Item Name"] === pickerItem.item) {
                        let newImages: {
                          urls: string[] | null;
                          imageName: string;
                        }[] = [];
                        pickerItem.selectedProductData &&
                          pickerItem.selectedProductData.forEach((product) => {
                            const newImageData = {
                              urls: product.images,
                              imageName: product.imageName,
                            };
                            newImages.push(newImageData);
                          });

                        item.images = newImages;
                      }
                    });
                  }
                });
              });
            }
          });
        });
      });

      dispatch({ type: "quote", value: copyOfQuote });

      setTimeout(() => {
        setIsLoading(false);
      }, 3000);
    };
    updateQuoteImages();
  }, []);

  const updateDesignData = async () => {
    let designDataBody = {
      quoteID: quote.quoteID.quoteID,
      picker: quotePickerData,
      designData: designData,
      designCanvas: designCanvasData,
    };

    let response = await fetchRequest(
      "/design-builder/design-data",
      "POST",
      designDataBody
    );
    if (response.success) {
      console.log("design-data", response.message);
      //  setShowSuccessModal(true)
    } else {
      console.log("design-data", response.message);
    }
  };

  const updateQuote = async () => {
    let response = await fetchRequest("/quotes/revision", "PATCH", {
      quoteID: quote?.quoteID,
      quoteData: quote?.quoteData,
    });
    if (response.success) {
      console.log("quotes/revision", response.message);
      //  setShowSuccessModal(true)
    } else {
      console.log("quotes/revision", response.message);
    }
  };

  const sendProjectInfoToAirtable = async () => {
    let params = {
      "Listing ID": quote.project.title,
      "Project Address": quote["project address"]
        ? quote["project address"]
        : "",
      "Quote ID": quote.quoteID.quoteID,
      // Creator: quote.project.Creator.name,
      Currency: quote.project.Currency,
      // "Start Date": quote.project["Deal created"],
      "Client Docs": null,
    };
    let payload = await fetchRequest(`/airtable/projects/`, "PATCH", params);
    if (payload.success) {
      console.log("Project data ", payload);
    } else {
      console.log("Project data ", payload);
    }
  };

  const getDesignBuilderPickerData = () => {
    const designBuilderPickerData: any = {};
    const copyOfQuote = { ...quote };
    copyOfQuote.quoteData.forEach((quoteData: any, index: number) => {
      designBuilderPickerData[quoteData.unit.fields["Item Name"]] =
        quotePickerData[index];
    });

    return designBuilderPickerData;
  };

  const finalizeDesignBuilder = async () => {
    // let finalized = await getAirtableData();
    // if (!finalized) {
    let data = {
      quoteID: quote.quoteID.quoteID,
      projectTitle: quote.project.title,
      designBuilderPickerData: getDesignBuilderPickerData(),
      projectCurrency: quote.projectCurrency,
      user: null,
    };
    let payload = await fetchRequest(
      `/airtable/moodboard/design-builder/finalize`,
      "POST",
      data
    );
    if (payload.success) {
      console.log(payload);
      // alert.show(<h3 style={{ color: '#242627' }}>SUCCESS</h3>);
    } else {
      console.log(payload);
      // alert.show(<h3 style={{ color: '#242627' }}>ERROR</h3>);
    }
    // }
  };

  const handleSendToClient = () => {
    updateDesignData();
    updateQuote();
    finalizeDesignBuilder();
    sendProjectInfoToAirtable();
    setShowSuccessModal(true);
  };

  return isLoading ? (
    <div className="design-page-body-spinner">
      <SpinnerCircular secondaryColor="#bdbdbd" color="#ff4e24" />
    </div>
  ) : (
    <React.Fragment>
      <div className="finalize-page-wrapper">
        <NavBar showFinalizeButton={false} showSaveButton={false} />
        <div className="finalize-page-body">
          <ClientView handleSendToClient={handleSendToClient} />
        </div>
      </div>
      <PageModal
        visible={showSuccessModal}
        component={<SuccessModal onClose={() => setShowSuccessModal(false)} />}
        onClose={() => setShowSuccessModal(false)}
      />
    </React.Fragment>
  );
};

export default FinalizePage;
