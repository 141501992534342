import React from "react";
import "./Line.scss";

type Props = {
  type: "vertical" | "horizontal";
  color?: string;
  height?: string;
  width?: string;
};

const Line: React.FC<Props> = ({ type, color, height, width }) => {
  let style: { [key: string]: string } = {};

  if (color) style.backgroundColor = color;
  if (height) style.minHeight = height;
  if (width) style.minWidth = width;

  return (
    <div
      draggable={false}
      id=""
      className={
        type === "horizontal"
          ? "horizontal-line-wrapper"
          : "vertical-line-wrapper"
      }
      style={style}
    ></div>
  );
};

export default Line;
