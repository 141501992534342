import React, { useEffect, useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import "./PageModal.scss";


type Props = {
  visible: boolean;
  component: JSX.Element;
  onClose: (event: React.MouseEvent) => void;
};

const PageModal:React.FC<Props> = ({visible, component, onClose}) => {
  const [show, setShow] = useState(visible);

  useEffect(() => {
setShow(visible)
  }, [visible])

  const handleCloseModal = (event:React.MouseEvent) => {
    event.preventDefault();
    if ((event.target as any).closest(".modal") === null) {
      onClose(event);
    }
  };

  return (
    <div
      className="page-modal-wrapper"
      style={{display: show ? "flex" : "none",}}
      // visible={visible.toString() && show.toString()}
    >
      <OutsideClickHandler
        onOutsideClick={(e) => {
          setShow(false);
          handleCloseModal(e);
        }}
      >
        <div id="cc-m" className="page-modal-modal">
          {component}
        </div>
      </OutsideClickHandler>
    </div>
  );
};

export default PageModal;
