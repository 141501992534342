import React from "react";
import { AiOutlineZoomIn, AiOutlineZoomOut } from "react-icons/ai";
import { CgEditFlipH } from "react-icons/cg";
import { FaAdjust, FaCropAlt, FaFileExport, FaListUl } from "react-icons/fa";
import { RiDashboardFill } from "react-icons/ri";
import MenuBarIcon from "./MenuBarIcon/MenuBarIcon";
import "./PlaygroundMenuBar.scss";



type Props = {

};

const PlaygroundMenuBar: React.FC<Props> = ({ }) =>
{

  return (
      <div className="playground-menubar-wrapper">
          <div className="playground-menubar-menus">
              <MenuBarIcon Icon={FaListUl} />
              <MenuBarIcon Icon={RiDashboardFill} />
              <MenuBarIcon Icon={FaFileExport} />
          </div>

          <div className="playground-menubar-menus">
              <MenuBarIcon Icon={FaCropAlt} />
              <MenuBarIcon Icon={FaAdjust} />
              <MenuBarIcon Icon={CgEditFlipH} />
          </div>

          <div className="playground-menubar-menus">
              <MenuBarIcon Icon={AiOutlineZoomIn} />
              <MenuBarIcon Icon={AiOutlineZoomOut} />
          </div>
    </div>
  );
};

export default PlaygroundMenuBar;
