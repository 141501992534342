import React from "react";
import "./BudgetDetails.scss";
import ProgressBar from "./ProgressBar/ProgressBar";

type Props = {
  title: string;
  totalNumberOfItems: number;
  currentItemCount: number;
};

const BudgetDetails: React.FC<Props> = ({
  title,
  totalNumberOfItems,
  currentItemCount,
}) =>
{

  return (
    <div className="budget-details-wrapper">
      <div className="budget-details-wrapper-row-item1">
      <h3>
        {title}
        </h3>
      </div>
      <div className="budget-details-wrapper-row-item2">
        <h3>{`${currentItemCount}/${totalNumberOfItems} items`}</h3>
        </div>
      <div className="budget-details-progress-bar budget-details-wrapper-row-item3">
        <ProgressBar
          completed={
            (currentItemCount * 100) / (totalNumberOfItems === 0
              ? 0
              : totalNumberOfItems)
          }
        />
      </div>
      
    </div>
  );
};

export default BudgetDetails;
