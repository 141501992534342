import React, { useEffect, useState } from "react";
import "./DesignCatalogue.scss";
import Filters from "./Filters/Filters";
import { FiSearch } from "react-icons/fi";
import Sort from "./Sort/Sort";
import getProducts from "../../../Shared/Functions/getProducts";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import ProductCard from "./ProductCard/ProductCard";
import getActiveItems from "../../../Shared/Functions/getActiveItems";
import { SpinnerCircular } from "spinners-react";
import NoResultsImage from "../../../../Images/no-results-found.png";
import InputBox from "../../../Shared/Components/InputBox/InputBox";
import lodash from "lodash";

type TReduxStateSelector = {
  project: object;
  quote: { quoteData: object[]; budget: number; projectCurrency: string };
  activeUnitIndex: number;
  productsData: object;
  productsFilter: object;
  fromAirtable: boolean;
};

const DesignCatalogue = React.memo(() => {
  const project: any = useSelector<TReduxStateSelector>(
    (state) => state.project
  );

  const quoteData: any = useSelector<TReduxStateSelector>(
    (state) => state.quote.quoteData
  );

  const activeUnitIndex: any = useSelector<TReduxStateSelector>(
    (state) => state.activeUnitIndex
  );

  const productsData: any = useSelector<TReduxStateSelector>(
    (state) => state.productsData
  );

  const productsFilter: any = useSelector<TReduxStateSelector>(
    (state) => state.productsFilter
  );

  const fromAirtable: any = useSelector<TReduxStateSelector>(
    (state) => state.fromAirtable
  );
  const projectCurrency: any = useSelector<TReduxStateSelector>(
    (state) => state.quote.projectCurrency
  );

  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [isLoadingMore, setIsLoadingMore] = useState<boolean>(false);

  const [searchInput, setSearchInput] = useState<string>("");

  useEffect(() => {
    if (!productsData) {
      fetchProducts();
    }
  }, []);

  const handleSearchInput = (event: React.ChangeEvent) => {
    const eventTarget = event.target as any;
    setSearchInput(eventTarget.value);
  };

  const handleSearchProductName = (productName: string) => {
    const copyOfProductsFilter = lodash.cloneDeep(productsFilter);
    copyOfProductsFilter.filterParams.itemName = productName;
    fetchProducts(
      copyOfProductsFilter.sort,
      1,
      copyOfProductsFilter.pageLimit,
      copyOfProductsFilter.filterParams
    );
  };

  const fetchProducts = async (
    sort = false,
    pageQuery = 1,
    pageLimit = 20,
    filterParams = {
      items: [],
      priceMin: 0,
      priceMax: 1000,
      priceType: "MSRP",
      projectAddress: project["project address"],
      radius: 800,
      width: null,
      height: null,
      length: null,
      weightMin: null,
      weightMax: null,
      itemName: null,
      color: null,
      similarImageURL: null,
      vendorName: null,
      vendorRegion: null,
      sortPrice: null,
      sortStockQuantity: null,
      weightUnit: null,
      dimensionUnit: null,
      currency: projectCurrency,
    },
    airtableFilter: any = "none"
  ) => {
    if (pageQuery === 1) setIsLoading(true);
    if (pageQuery > 1) setIsLoadingMore(true);

    if (filterParams.items.length < 1) {
      filterParams.items = getActiveItems(quoteData[activeUnitIndex], "All");
    }

    const currentAirtableFilter =
      airtableFilter !== "none" ? airtableFilter : fromAirtable;
    let newProductsData = await getProducts(
      sort,
      pageQuery,
      pageLimit,
      filterParams,
      currentAirtableFilter
    );

    if (!newProductsData.success) {
      alert(newProductsData.message);
      return;
    }

    const { items, vendorsWithinRadius } = newProductsData;

    const { filteredItems, highestPrice, lowestPrice, totalCount } = items;

    let newProducts = filteredItems;
    if (productsData && productsData.products && pageQuery > 1) {
      newProducts = [...productsData.products, ...filteredItems];
    }

    setIsLoading(false);
    setIsLoadingMore(false);

    dispatch({
      type: "productsFilter",
      value: {
        sort,
        pageQuery,
        pageLimit,
        filterParams,
      },
    });

    dispatch({
      type: "productsData",
      value: {
        products: newProducts,
        highestPrice,
        lowestPrice,
        totalCount,
        vendorsWithinRadius,
      },
    });
  };

  return (
    <div className="design-catalogue-wrapper">
      {isLoading && <div className="design-catalogue-wrapper-overlay"></div>}
      <Filters
        handleFilterProducts={(fd: any) => {
          fetchProducts(fd.sort, 1, fd.pageLimit, fd.filterParams);
        }}
        handleFilterProductsFromAirtable={(v) => {
          fetchProducts(
            productsFilter.sort,
            1,
            productsFilter.pageLimit,
            productsFilter.filterParams,
            v
          );
        }}
      />
      <div className="design-catalogue-mid-section">
        <div className="design-catalogue-search-and-result-row">
          <h5 className="design-catalogue-search-result-count">
            {`Results: ${productsData ? productsData.totalCount : 0}`}
          </h5>
          <InputBox
            name="searchInput"
            type="text"
            value={searchInput}
            placeholder="search product name"
            onChange={handleSearchInput}
          />
          <FiSearch
            className="design-catalogue-search-icon"
            onClick={() => handleSearchProductName(searchInput)}
          />
        </div>
        <Sort
          handleSortProducts={(fd: any) => {
            console.log("DesignCatalogue:: sort handler called w fd=", fd);
            fetchProducts(fd.sort, 1, fd.pageLimit, fd.filterParams);
          }}
        />
      </div>
      {isLoading ? (
        <div
          className="design-catalogue-products"
          style={{ paddingTop: "30%" }}
        >
          <SpinnerCircular secondaryColor="#bdbdbd" color="#ff4e24" />
        </div>
      ) : (
        <div className="design-catalogue-products">
          <div className="design-catalogue-products-container">
            <div className="design-catalogue-products-row">
              {productsData && productsData.products.length > 0
                ? productsData.products.map((product: any) => (
                    <ProductCard
                      key={uuidv4()}
                      product={product}
                      quoteData={quoteData[activeUnitIndex]}
                    />
                  ))
                : !isLoading && (
                    <div
                      className="design-catalogue-no-products"
                      style={{ paddingTop: "30%" }}
                    >
                      <img
                        width="200px"
                        src={NoResultsImage}
                        alt="no-results"
                      />
                      <h5>No results found</h5>
                    </div>
                  )}
            </div>
            <div>
              {isLoadingMore ? (
                <div className="design-load-more-button">
                  <SpinnerCircular secondaryColor="#bdbdbd" color="#ff4e24" />
                </div>
              ) : (
                productsData &&
                productsData.products.length > 0 &&
                productsData.totalCount > productsData.products.length && (
                  <button
                    className="button design-load-more-button"
                    onClick={() =>
                      fetchProducts(
                        productsFilter.sort,
                        productsFilter.pageQuery + 1,
                        productsFilter.pageLimit,
                        productsFilter.filterParams
                      )
                    }
                  >
                    Load More
                  </button>
                )
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
});

export default DesignCatalogue;
