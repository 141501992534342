import React, { useState } from "react";
import "./Selector.scss";
import { v4 as uuidv4 } from "uuid";
import "../../../Shared/Classes/Button.scss";

type Props = {
  selectors: string[];
  selectorWidth?: string;
  handleSelection: (selected: string) => void;
};

const Selector: React.FC<Props> = ({
  selectors,
  selectorWidth,
  handleSelection,
}) => {
  type SActiveSelectorIndex = number;

  const [activeSelectorIndex, setActiveSelectorIndex] = useState<
    SActiveSelectorIndex
  >(1);

  const handleSelect = (event: React.MouseEvent, index: number) => {
    event.preventDefault();
    event.stopPropagation();
    setActiveSelectorIndex(index);
    handleSelection(selectors[index]);
  };

  return (
    <div
      className="selector-wrapper"
      style={{
        width: selectorWidth,
      }}
    >
      {selectors.map((selector, index) => (
        <button
          key={uuidv4()}
          className={
            index === activeSelectorIndex
              ? "button selector selector-active"
              : "button selector"
          }
          style={{
            borderRadius:
              index === 0
                ? "5px 0 0 5px"
                : index === selectors.length - 1
                ? "0 5px 5px 0"
                : "0",
            width: (100 / selectors.length).toString() + "%",
          }}
          onClick={(e) => handleSelect(e, index)}
        >
          {selector}
        </button>
      ))}
    </div>
  );
};

export default Selector;
