import fetchRequest from "./fetchRequest";

type TFilterParams = {
  items: string[];
  priceMin: number;
  priceMax: number;
  priceType: string;
  projectAddress: string;
  radius: number;
  width: number | null;
  height: number | null;
  length: number | null;
  itemName: string | null;
  color: string | null;
  similarImageURL: string | null;
  vendorName: string | null;
  sortPrice: number | null;
  weightMin: null;
  weightMax: null;
  sortStockQuantity: null;
  weightUnit: null;
  dimensionUnit: null;
};

type TGetProducts = (
  sort: boolean,
  pageQuery: number,
  pageLimit: number,
  filterParams: TFilterParams,
  fromAirtable: boolean
) => Promise<any>;

const getProducts: TGetProducts = async (
  sort,
  pageQuery,
  pageLimit,
  filterParams,
  fromAirtable = false
) => {
  const queryParams = {
    ...filterParams,
    page: pageQuery,
    limit: pageLimit,
    fromAirtable,
  };

  const products = await fetchRequest(
    "/items/query?params=" + encodeURIComponent(JSON.stringify(queryParams)),
    "GET"
  );

  return products;
};

export default getProducts;
