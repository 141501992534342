import React from "react";
import "./DraftTabs.scss";
import { v4 as uuidv4 } from "uuid";
import { AiOutlinePlus } from "react-icons/ai";
import { TItemData } from "../../Types/PickerTypes";

type Props = {
    items: TItemData[][];
    activeItemDataIndex: number;
};

const DraftTabs: React.FC<Props> = ({ items,activeItemDataIndex }) => {

  return (
        <div className="draft-tabs-wrapper">
          {items.map((item, index) => <div key={uuidv4()} className={`draft-tabs-tab ${index === activeItemDataIndex  && 'draft-tabs-tab-active'}`} >
          {`Draft ${index + 1}`}
        </div>
          )} 
          <div className="draft-tabs-tab">
            <AiOutlinePlus className="draft-tabs-tab-add-icon"/>
          </div>
        </div>
  );
};

export default DraftTabs;