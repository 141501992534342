import React, { useEffect, useState } from "react";
import "./ProgressBar.scss";

type Props = {
  completed: number;
};

const ProgressBar: React.FC<Props> = ({ completed }) =>
{

  const [style, setStyle] = useState<{[key:string]:string}>({})
  
  useEffect(() => {
  let style: { [key: string]: string } = {};
  style.width = `${completed}%`;

  if (completed < 0 || completed > 100) {
    style.width = "100%";
    style.backgroundColor = "#FF0015";
  }
    
    setStyle(style)
    console.log(style, completed)
  }, [completed])


  return (
    <div className="progress-bar-wrapper">
      <div style={style}></div>
    </div>
  );
};

export default ProgressBar;
