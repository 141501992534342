import React from "react";
import { Route, BrowserRouter, Switch, Redirect } from "react-router-dom";
import DesignPage from "./Components/Pages/DesignPage/DesignPage";
import FinalizePage from "./Components/Pages/FinalizePage/FinalizePage";
import LandingPage from "./Components/Pages/LandingPage/LandingPage";
import PageNotFound from "./Components/Pages/PageNotFound/PageNotFound";

const App = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact={true} path="/" component={LandingPage} />
        <Route exact={true} path="/design" component={DesignPage} />
        <Route exact={true} path="/finalize" component={FinalizePage} />
        <Route
          exact={true}
          path="/design/:projectID/:projectName"
          component={DesignPage}
        />
        <Route exact={true} path="/404" component={PageNotFound} />
        <Redirect to="/404" />
      </Switch>
    </BrowserRouter>
  );
};

export default App;
