import React from "react";
import "./FilterActionButtons.scss";
import "../../../../../Shared/Classes/Button.scss";

type Props = {
  onClick: (actionName: string) => void;
};

const FilterActionButtons: React.FC<Props> = ({ onClick }) => {
  const handleButtonAction = (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    onClick((event.target as any).name);
  };

  return (
    <div className="filter-action-buttons-wrapper">
      <button
        name="cancel"
        className="button filter-action-button"
        onClick={handleButtonAction}
      >
        Cancel
      </button>
      <button
        name="apply"
        className="button filter-action-button filter-action-button-apply"
        onClick={handleButtonAction}
      >
        Apply
      </button>
    </div>
  );
};

export default FilterActionButtons;
